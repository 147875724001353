<template>
  <div>
    <v-skeleton-loader type="heading" />
    <v-row>
      <v-col>
        <v-skeleton-loader type="table" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader type="card" />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader type="card" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader type="table" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PpmCalcSkeleton'
}
</script>
