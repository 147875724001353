<!--
Shows a search/list page for the Catalog
-->
<template>
  <unauthorized
    :authorized="$store.getters.can('view-catalogs')"
    message="Unauthorized to view catalogs"
  >
    <v-container fluid>
      <base-title title="Catalogs" />
      <catalog-advanced-search />
    </v-container>
  </unauthorized>
</template>

<script>
import CatalogAdvancedSearch from './Components/CatalogAdvancedSearch'

export default {
  name: 'CatalogList',
  components: {
    CatalogAdvancedSearch
  }
}
</script>
