<template>
  <v-sheet>
    <v-card
      flat
      tile
    >
      <v-card-title>
        Converter Pricing
      </v-card-title>
      <v-card-text>
        Pricing occurs in a matter of steps
        <ol>
          <li>
            Initial_Price = Yield_Lbs * 14.583 / 1000000 * Dollar_Value * Return_Rate
            <ul>
              <li>
                The initial price formula is applied for each metal.
              </li>
              <li>
                Yield_Lbs * 14.583 / 1000000 = Contained Troy OZ
              </li>
              <li>
                Dollar_Value references what our Market Rate for the metal
              </li>
              <li>
                Return_Rate references the expected smelter return rate
              </li>
            </ul>
          </li>
          <li>Price = Initial_Price * CGP (<strong>CATEGORY ONLY</strong>)</li>
          <li>Price = Initial_Price - (TP - SP/100) (<strong>CHECK IN ONLY</strong>)</li>
        </ol>
        <br>
        <p class="subtitle-2">
          Pricing Calculator
        </p>
        <v-row>
          <v-col
            sm="3"
            md="2"
          >
            <v-text-field
              dense
              type="number"
              label="Platinum Value"
              v-model="ptMarket"
            />
            <v-text-field
              dense
              type="number"
              label="Palladium Value"
              v-model="pdMarket"
            />
            <v-text-field
              dense
              type="number"
              label="Rhodium Value"
              v-model="rhMarket"
            />
          </v-col>
          <v-col
            sm="3"
            md="2"
          >
            <v-text-field
              dense
              type="number"
              label="Platinum Return Rate"
              v-model="ptReturnMarket"
            />
            <v-text-field
              dense
              type="number"
              label="Palladium Return Rate"
              v-model="pdReturnMarket"
            />
            <v-text-field
              dense
              type="number"
              label="Rhodium Return Rate"
              v-model="rhReturnMarket"
            />
          </v-col>
        </v-row>
        <p class="subtitle-2">
          Converter/Options
        </p>
        <v-row>
          <v-col
            sm="3"
            md="2"
          >
            <v-text-field
              dense
              type="number"
              label="Yield"
              v-model="converterYield"
            />
            <v-text-field
              dense
              type="number"
              label="Platinum PPM"
              v-model="converterPtPPM"
            />
            <v-text-field
              dense
              type="number"
              label="Palladium PPM"
              v-model="converterPdPPM"
            />
            <v-text-field
              dense
              type="number"
              label="Rhodium PPM"
              v-model="converterRhPPM"
            />
          </v-col>
          <v-col
            sm="3"
            md="2"
          >
            <v-text-field
              dense
              type="number"
              label="Category Percentage (Category Pricing Only)"
              v-model="categoryPercent"
            />
            <v-text-field
              dense
              type="number"
              label="Check In Spread (Check in Pricing Only)"
              v-model="spread"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            sm="3"
            md="2"
          >
            <v-checkbox
              label="Is Category"
              v-model="isCategory"
            />
          </v-col>
          <v-col
            sm="3"
            md="2"
          >
            <v-checkbox
              label="Use Spread"
              v-model="useSpread"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Start by Converting lbs into a millionth of a contained troy OZ
            <p class="title">
              ({{ this.converterYield }} * 14.583) / 1000000 = {{ contained_toz }}toz
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Calculate $ amount Platinum
            <p class="title">
              toz * {{ converterPtPPM }}ppm * ${{ ptMarket }} * {{ ptReturnMarket * 100 }}% = ${{ Math.round(total_pt) }}<sup>[pt]</sup>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Calculate $ amount Palladium
            <p class="title">
              toz * {{ converterPdPPM }}ppm * ${{ pdMarket }} * {{ pdReturnMarket * 100 }}% = ${{ Math.round(total_pd) }}<sup>[pd]</sup>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Calculate $ amount Rhodium
            <p class="title">
              toz * {{ converterRhPPM }}ppm * ${{ rhMarket }} * {{ rhReturnMarket * 100 }}% = ${{ Math.round(total_rh) }}<sup>[rh]</sup>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Add the prices together and apply the Category Percentage and Spread
            <p class="title">
              (${{ Math.round(total_pt) }}<sup>[pt]</sup> + ${{ Math.round(total_pd) }}<sup>[pd]</sup> + ${{ Math.round(total_rh) }}<sup>[rh]</sup>)
              <span v-if="isCategory">&nbsp;* {{ categoryPercent * 100 }}% </span>
              <span v-if="useSpread">&nbsp;- ${{ Math.round(calculateSpread) }}</span>
              &nbsp;= <strong>${{ Math.round(grand_total) }}</strong>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      flat
      tile
    >
      <v-card-title>
        Catalog Pricing
      </v-card-title>
      <v-card-text>
        The simplest form of pricing that is set on the CATALOG entry and is not modified an any way for purchases.
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: 'PricingExample',
  data: () => ({
    ptMarket: 840,
    pdMarket: 1930,
    rhMarket: 8000,
    ptReturnMarket: 0.97,
    pdReturnMarket: 0.97,
    rhReturnMarket: 0.91,
    converterYield: 3.62,
    converterPtPPM: 565,
    converterPdPPM: 2459,
    converterRhPPM: 194,
    categoryPercent: 0.9,
    spread: 30,
    isCategory: false,
    useSpread: false
  }),
  computed: {
    contained_toz () {
      return (this.converterYield * 14.583) / 1000000
    },
    total_pt () {
      return this.contained_toz * this.converterPtPPM * this.ptMarket * this.ptReturnMarket
    },
    total_pd () {
      return this.contained_toz * this.converterPdPPM * this.pdMarket * this.pdReturnMarket
    },
    total_rh () {
      return this.contained_toz * this.converterRhPPM * this.rhMarket * this.rhReturnMarket
    },
    pre_total () {
      return this.total_pt + this.total_pd + this.total_rh
    },
    grand_total () {
      return this.applySpread(this.applyCategory(this.pre_total))
    },
    calculateSpread () {
      return this.applyCategory(this.pre_total) * this.spread / 100
    }
  },
  methods: {
    applyCategory (total) {
      total = Number(total)
      if (this.isCategory) total = total * Number(this.categoryPercent)
      return total
    },
    applySpread (total) {
      total = Number(total)
      if (this.useSpread) total = total - this.calculateSpread
      return total
    }
  }
}
</script>
