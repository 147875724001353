<template>
  <ValidationObserver
    v-slot="{ invalid }"
    ref="validationObserver"
  >
    <edit-dialog
      :value="value"
      headline-text="Edit PPM"
      :inputs-valid="!invalid"
      @input="$emit('input', $event)"
      @accept="acceptChanges"
    >
      <v-form>
        <v-container>
          <v-row>
            <v-col>
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Name"
                  v-model="name"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                v-model="manual"
                :label="manual ? 'Manual' : 'Use Filters - Automatic'"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Notes"
                v-model="memo"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </edit-dialog>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'PpmEditMeta',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    ppmCalculation: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      name: '',
      memo: '',
      manual: false
    }
  },
  watch: {
    ppmCalculation: {
      immediate: true,
      handler (ppmCalc) {
        this.name = ppmCalc.name
        this.manual = ppmCalc.is_manual
        this.memo = ppmCalc.memo
      }
    }
  },
  methods: {
    async acceptChanges () {
      const payload = { id: this.ppmCalculation.id, name: this.name, is_manual: this.manual, memo: this.memo }
      await this.$store.dispatch('ppmCalculationStore/updatePpmCalculation', payload)
      this.$emit('input', false)
    }
  }
}
</script>
