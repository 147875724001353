var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Description","error-messages":errors},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Material Type","items":_vm.catalogTypes,"item-text":"text","item-value":"value","error-messages":errors},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Unit of Measure","items":_vm.unitTypes,"item-text":"text","item-value":"value","error-messages":errors},model:{value:(_vm.unitOfMeasure),callback:function ($$v) {_vm.unitOfMeasure=$$v},expression:"unitOfMeasure"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.type !== 'CV'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-text-field',{attrs:{"disabled":_vm.type === 'CV',"label":"Price"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1)]}}],null,true)},[_c('span',[_vm._v("Converters do not require a manual price")])])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col'):_vm._e()],1),_c('base-button',{attrs:{"auto-center":"","disabled":invalid || _vm.loading,"loading":_vm.loading,"label":"Create"},on:{"clicked":_vm.createCatalog}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }