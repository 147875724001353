var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.computedTableHeaders,"item-key":"id","items":_vm.lotSummaries,"no-data-text":"No Applicable Lots","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.exclude",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('excludeSummary', item.url)}}},[_c('v-icon',[_vm._v(" mdi-minus-circle ")])],1)]}},{key:"item.lot_id",fn:function(ref){
var item = ref.item;
return [(item.lot_id)?_c('router-link-id',{attrs:{"id":item.lot_id,"name":"lot"}},[_vm._v(" "+_vm._s(item.lot_name)+" ")]):_vm._e()]}},{key:"item.shipment_summary_id",fn:function(ref){
var item = ref.item;
return [_c('router-link-id',{attrs:{"id":item.shipment_summary_id,"name":"shipment-summary"}},[_vm._v(" "+_vm._s(item.shipment_summary_id)+" ")])]}},{key:"item.lot_designation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDesignation(item))+" ")]}},{key:"item.lot_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCategory(item))+" ")]}},{key:"item.lot_yard",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.yard_names.join(', '))+" ")]}},{key:"item.contained_pt_ppm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toFixed")(item.contained_pt_ppm,3))+" ")]}},{key:"item.contained_pd_ppm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toFixed")(item.contained_pd_ppm,3))+" ")]}},{key:"item.contained_rh_ppm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toFixed")(item.contained_rh_ppm,3))+" ")]}},{key:"item.carbon_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("appendPercent")(_vm._f("toFixed")(item.carbon_percent,2)))+" ")]}},{key:"item.percent_moisture",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("appendPercent")(_vm._f("toFixed")(item.percent_moisture,2)))+" ")]}},(_vm.editing)?{key:"item.lot_pieces",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [(item.lot_id)?_c('v-text-field',{attrs:{"type":"number"},model:{value:(item.lot_pieces),callback:function ($$v) {_vm.$set(item, "lot_pieces", $$v)},expression:"item.lot_pieces"}}):_vm._e(),_c('v-btn',{staticClass:"mb-2",attrs:{"text":"","color":"primary","small":""},on:{"click":function($event){return _vm.updatePieces(item.lot_name, item.lot_id, item.lot_pieces)}}},[_vm._v(" click to save ")])]},proxy:true}],null,true)},[_c('span',{staticClass:"text-no-wrap",class:{ 'red--text': item.lot_pieces === 0 }},[_vm._v(" "+_vm._s(item.lot_pieces)+" "),(item.lot_id)?_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-pencil ")]):_vm._e()],1)])]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }