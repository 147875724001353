<template>
  <div>
    <v-data-table
      :headers="computedTableHeaders"
      item-key="id"
      :items="lotSummaries"
      no-data-text="No Applicable Lots"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.exclude="{ item }">
        <v-btn
          @click="$emit('excludeSummary', item.url)"
          icon
        >
          <v-icon>
            mdi-minus-circle
          </v-icon>
        </v-btn>
      </template>
      <template
        v-slot:item.lot_id="{ item }"
      >
        <router-link-id
          v-if="item.lot_id"
          :id="item.lot_id"
          name="lot"
        >
          {{ item.lot_name }}
        </router-link-id>
      </template>
      <template v-slot:item.shipment_summary_id="{ item }">
        <router-link-id
          :id="item.shipment_summary_id"
          name="shipment-summary"
        >
          {{ item.shipment_summary_id }}
        </router-link-id>
      </template>
      <template v-slot:item.lot_designation="{ item }">
        {{ getDesignation(item) }}
      </template>
      <template v-slot:item.lot_type="{ item }">
        {{ getCategory(item) }}
      </template>
      <template v-slot:item.lot_yard="{ item }">
        {{ item.yard_names.join(', ') }}
      </template>
      <template v-slot:item.contained_pt_ppm="{ item }">
        {{ item.contained_pt_ppm | toFixed(3) }}
      </template>
      <template v-slot:item.contained_pd_ppm="{ item }">
        {{ item.contained_pd_ppm | toFixed(3) }}
      </template>
      <template v-slot:item.contained_rh_ppm="{ item }">
        {{ item.contained_rh_ppm | toFixed(3) }}
      </template>
      <template v-slot:item.carbon_percent="{ item }">
        {{ item.carbon_percent | toFixed(2) | appendPercent }}
      </template>
      <template v-slot:item.percent_moisture="{ item }">
        {{ item.percent_moisture | toFixed(2) | appendPercent }}
      </template>
      <template
        v-if="editing"
        v-slot:item.lot_pieces="{ item }"
      >
        <v-edit-dialog>
          <span
            :class="{ 'red--text': item.lot_pieces === 0 }"
            class="text-no-wrap"
          >
            {{ item.lot_pieces }}
            <v-icon
              v-if="item.lot_id"
              x-small
            >
              mdi-pencil
            </v-icon>
          </span>
          <template v-slot:input>
            <v-text-field
              v-if="item.lot_id"
              v-model="item.lot_pieces"
              type="number"
            />
            <v-btn
              text
              class="mb-2"
              color="primary"
              small
              @click="updatePieces(item.lot_name, item.lot_id, item.lot_pieces)"
            >
              click to save
            </v-btn>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'LotsTable',
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    lotSummaries: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('lotStore', [
      'lotTypes', // Designation
      'lotMaterialType' // Category
    ]),
    // Show exclude option if editing
    computedTableHeaders () {
      // allowing for more dynamic headers
      let headers = [
        { text: 'Lot', value: 'lot_id' },
        { text: 'Calc', value: 'ppm_name' },
        { text: 'Shipment Summary', value: 'shipment_summary_id' },
        { text: 'Designation', value: 'lot_designation' },
        { text: 'Category', value: 'lot_type' },
        { text: 'Yard', value: 'lot_yard' },
        { text: 'Pt PPM', value: 'contained_pt_ppm' },
        { text: 'Pd PPM', value: 'contained_pd_ppm' },
        { text: 'Rh PPM', value: 'contained_rh_ppm' },
        { text: 'Pieces', value: 'lot_pieces' },
        { text: 'Carbon', value: 'carbon_percent' },
        { text: 'Moisture', value: 'percent_moisture' },
        { text: 'Scrap LBS', value: 'scrap_lbs' },
        { text: 'Gross LBS', value: 'gross_lbs' },
        { text: 'Net Wet LBS', value: 'net_wet_lbs' },
        { text: 'Value Date', value: 'value_date' }
      ]

      if (this.editing) headers.unshift({ text: 'Exclude', value: 'exclude' })
      return headers
    }
  },
  methods: {
    getDesignation (summary) {
      const found = this.lotTypes.find(d => d.value === summary.lot_designation)
      return found ? found.text : summary.lot_designation
    },
    getCategory (summary) {
      const found = this.lotMaterialType.find(d => d.value === summary.lot_type)
      return found ? found.text : summary.lot_type
    },
    updatePieces (lotName, lotId, pieces) {
      axios.patch(`/api/lots/${lotId}/`, { piece_count: pieces }).then(() => {
        this.$store.commit('setSnackbarInfo', `Set Lot ${lotName} Piece Count to ${pieces}`)
        this.$emit('valuesChanged')
      })
    }
  }
}
</script>
