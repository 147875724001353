var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.can('view-catalogs'),"message":"Unauthorized to view catalogs"}},[(_vm.loading)?_c('app-loading-indicator'):_c('v-container',[(_vm.catalog)?_c('url-breadcrumbs',{attrs:{"items":[
        ['catalog list', { name: 'catalog-list' }],
        _vm.catalog.url
      ]}}):_vm._e(),_c('base-title',[_vm._v(" "+_vm._s(("Catalog ID #" + (_vm.catalog.id)))+" ")]),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('merge-catalog-dialog',{attrs:{"catalog":_vm.catalog},on:{"merged":_vm.loadData},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":!_vm.$store.getters.can('merge-catalogs'),"small":"","color":"primary"},on:{"click":on}},[_vm._v(" Merge Catalog ")])]}}])}),(_vm.can('create-assays') && _vm.catalog.converter !== null)?_c('add-assay',{attrs:{"button-color":"primary","adding-assay":_vm.addingAssay},on:{"addAssay":_vm.addAssay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v(" Add Assay ")])]}}],null,false,2411919195)}):_vm._e()],1),_c('catalog-section',{attrs:{"catalog":_vm.catalog,"catalog-types":_vm.catalogTypes}}),_c('br'),(_vm.catalog.material_type === 'CV' && _vm.catalog.converter !== null)?_c('converter-section',{attrs:{"assays":_vm.assays,"catalog":_vm.catalog}}):_vm._e(),_c('br'),(_vm.assays && _vm.assays.length > 0 && _vm.can('view-assays'))?_c('assay-section',{attrs:{"assays":_vm.assays,"manageable":""},on:{"assayDeleted":_vm.loadData}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }