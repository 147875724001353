<template>
  <div>
    <v-col>
      <v-simple-table>
        <thead>
          <tr>
            <th />
            <th>PT</th>
            <th>PD</th>
            <th>RH</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Average PPMs
              <info-tooltip>
                Weighted Average of Contained PPM using {{ ppmCalculation.weighted_field }}
              </info-tooltip>
            </td>
            <td class="pt-color">
              {{ ppmCalculation.url ? ppmCalculation.pt_ppm : ppmCalculation.contained_pt_ppm }}
            </td>
            <td class="pd-color">
              {{ ppmCalculation.url ? ppmCalculation.pd_ppm : ppmCalculation.contained_pd_ppm }}
            </td>
            <td class="rh-color">
              {{ ppmCalculation.url ? ppmCalculation.rh_ppm : ppmCalculation.contained_rh_ppm }}
            </td>
          </tr>
          <tr>
            <td>
              Est. Oz per piece
              <info-tooltip>
                Cumulative Contained toz divided by Lot declared Total Pieces
              </info-tooltip>
            </td>
            <td class="pt-color">
              {{ ppmCalculation.pt_ctoz_per_pc | filterNA }}
            </td>
            <td class="pd-color">
              {{ ppmCalculation.pd_ctoz_per_pc | filterNA }}
            </td>
            <td class="rh-color">
              {{ ppmCalculation.rh_ctoz_per_pc | filterNA }}
            </td>
          </tr>
          <tr>
            <td>
              Est. Oz per pound
              <info-tooltip>
                Cumulative Contained toz divided by {{ ppmCalculation.weighted_field }}
              </info-tooltip>
            </td>
            <td class="pt-color">
              {{ ppmCalculation.pt_ctoz_per_lb }}
            </td>
            <td class="pd-color">
              {{ ppmCalculation.pd_ctoz_per_lb }}
            </td>
            <td class="rh-color">
              {{ ppmCalculation.rh_ctoz_per_lb }}
            </td>
          </tr>
          <tr v-if="!foilsCalc">
            <td>
              Average Carbon
              <info-tooltip>
                Weighted Average of carbon_percent using net_wet_lbs
              </info-tooltip>
            </td>
            <td>
              {{ ppmCalculation.carbon_percent | appendPercent }}
            </td>
            <td colspan="2" />
          </tr>
          <tr v-if="!foilsCalc">
            <td>
              Average Moisture
              <info-tooltip>
                Weighted Average of percent_moisture using net_wet_lbs
              </info-tooltip>
            </td>
            <td>
              {{ ppmCalculation.moisture_percent | appendPercent }}
            </td>
            <td colspan="2" />
          </tr>
          <tr v-if="!foilsCalc">
            <td>
              Average Scrap Per Lb
              <info-tooltip>
                Cumulative scrap_lbs divided by net_wet_lbs
              </info-tooltip>
            </td>
            <td>
              {{ ppmCalculation.scrap_per_lb | appendLbs }}
            </td>
            <td colspan="2" />
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'PpmValues',
  props: {
    ppmCalculation: {
      type: Object,
      required: true
    },
    foilsCalc: {
      type: Boolean,
      required: true
    }
  }
}
</script>
