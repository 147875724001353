<template>
  <unauthorized
    :authorized="can('view-catalogs')"
    message="Unauthorized to view catalogs"
  >
    <app-loading-indicator v-if="loading" />
    <v-container v-else>
      <url-breadcrumbs
        v-if="catalog"
        :items="[
          ['catalog list', { name: 'catalog-list' }],
          catalog.url
        ]"
      />
      <base-title>
        {{ `Catalog ID #${catalog.id}` }}
      </base-title>
      <v-toolbar flat>
        <v-spacer />
        <merge-catalog-dialog
          :catalog="catalog"
          @merged="loadData"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="!$store.getters.can('merge-catalogs')"
              class="mx-2"
              small
              color="primary"
              @click="on"
            >
              Merge Catalog
            </v-btn>
          </template>
        </merge-catalog-dialog>
        <add-assay
          v-if="can('create-assays') && catalog.converter !== null"
          button-color="primary"
          :adding-assay="addingAssay"
          @addAssay="addAssay"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="primary"
              v-on="on"
            >
              Add Assay
            </v-btn>
          </template>
        </add-assay>
      </v-toolbar>
      <catalog-section
        :catalog="catalog"
        :catalog-types="catalogTypes"
      />
      <br>
      <converter-section
        v-if="catalog.material_type === 'CV' && catalog.converter !== null"
        :assays="assays"
        :catalog="catalog"
      />
      <br>
      <assay-section
        v-if="assays && assays.length > 0 && can('view-assays')"
        :assays="assays"
        manageable
        @assayDeleted="loadData"
      />
    </v-container>
  </unauthorized>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddAssay from './Components/AddAssay'
import CatalogSection from './Components/CatalogSection'
import ConverterSection from './Components/ConverterSection'
import AssaySection from './Components/AssaySection'
import axios from 'axios'
import MergeCatalogDialog from '@/views/Catalog/Components/MergeCatalogDialog'

export default {
  name: 'Catalog',
  components: {
    MergeCatalogDialog,
    AddAssay,
    AssaySection,
    CatalogSection,
    ConverterSection
  },
  data: () => ({
    mergeDialog: false,
    loadImages: false,
    loading: true,
    addingAssay: false,
    images: []
  }),
  methods: {
    ...mapActions({
      fetchCatalog: 'catalogStore/fetchCatalog',
      fetchAssays: 'catalogStore/fetchAssays'
    }),
    /**
     * Get the catalog and any images attached
     */
    async loadData () {
      try {
        const converter = await this.fetchCatalog(this.$route.params.id)
        // get the converter images if available
        if (converter && converter.picture_set) {
          const response = await axios.get(converter.picture_set.url)
          this.images = response.data.images
        }
        if (converter && this.can('view-assays')) await this.fetchAssays({ converter_id: converter.id })
      } finally {
        this.loading = false
      }
    },
    /**
     * Create a new assay and tell the child component when to load
     * @param {Object} body
     */
    addAssay (body) {
      this.addingAssay = true
      body.converter_id = this.catalog.converter.id
      this.$store.dispatch('catalogStore/addAssay', body).then(() => {
        this.$store.dispatch('catalogStore/fetchConverterPrice')
        this.fetchAssays({ converter_id: this.catalog.converter.id }).then(() => {
          this.addingAssay = false
        })
      })
    }
  },
  computed: {
    ...mapGetters({
      catalog: 'catalogStore/catalog',
      catalogTypes: 'catalogStore/catalogTypes',
      converterImages: 'catalogStore/converterImages',
      assays: 'catalogStore/assays',
      can: 'can'
    })
  },
  mounted () {
    Promise.all([ this.loadData() ]).then(() => { this.loading = false })
  }
}
</script>
