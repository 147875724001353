<template>
  <unauthorized
    :authorized="$store.getters.can('view-photo-groups')"
    message="Unauthorized to view Photo Groups"
  >
    <v-container fluid>
      <base-title title="Photo Groups" />
      <search-filters
        v-model="search"
        :chips="chips"
        @clearChip="clearChipProp"
        @clearFields="clearFields"
        @submitSearch="routePage"
      >
        <date-range-input
          :start-date="startDate"
          :end-date="endDate"
          @alterEndDate="endDate = $event"
          @alterStartDate="startDate = $event"
        />
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              label="ID"
              v-model="id"
              type="number"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              label="Name"
              v-model="name"
            />
          </v-col>
        </v-row>
      </search-filters>
      <result-pagination
        v-if="pagination"
        v-model="pagination.page"
        :pages="Math.ceil(totalCount / 20)"
      />
      <v-data-table
        multi-sort
        :headers="headers"
        :items="resultList"
        :server-items-length="totalCount"
        :options.sync="pagination"
        :footer-props="{ 'items-per-page-options': [20] }"
        @page-count="pageCount = $event"
        hide-default-footer
      >
        <template v-slot:item.id="{ item }">
          <router-link-id
            :id="item.id"
            name="photo-group"
          />
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.name | filterNA }}
        </template>
        <template v-slot:item.primary_image="{ item }">
          <div v-if="item.primary_image_url">
            <v-img
              :src="item.primary_image_url"
              max-height="300"
              max-width="300"
            />
          </div>
          <p v-else>
            No Image
          </p>
        </template>
      </v-data-table>
      <result-pagination
        v-if="pagination"
        v-model="pagination.page"
        :pages="Math.ceil(totalCount / 20)"
      />
    </v-container>
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import axios from 'axios'
import ResultPagination from '@/base/components/ResultPagination'

export default {
  mixins: [chipsMixin, paginationMixin],
  name: 'PhotoGroupList',
  components: { ResultPagination, SearchFilters },
  data: function () {
    return {
      pageName: 'photo-group-list',

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Preview', value: 'primary_image' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' },
        { name: 'End Date', prop: 'endDate', type: String, query: 'end_date' },
        { name: 'Start Date', prop: 'startDate', type: String, query: 'start_date' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    // Creates and submits query parameters
    async executeSearch () {
      const params = this.getQueryParams()
      params.fields = [
        'url',
        'id',
        'name',
        'primary_image_url',
        'primary_image'
      ].join(',')
      axios.get('/api/converter_image_groups/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
