<template>
  <edit-dialog
    :value="value"
    headline-text="Manual PPM"
    :inputs-valid="validData"
    @input="$emit('input', $event)"
    @accept="savePpmCalc"
  >
    <v-card-text>
      <v-form
        v-model="validData"
        @submit.prevent=""
      >
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="pt"
              :rules="rulesWhole"
              hint="0000"
              label="Pt PPM"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="pd"
              :rules="rulesWhole"
              hint="0000"
              label="Pd PPM"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="rh"
              :rules="rulesWhole"
              hint="0000"
              label="Rh PPM"
              type="number"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="ptCtozPerPc"
              :rules="rules76"
              label="Pt ctoz per piece"
              hint="0.000000"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="pdCtozPerPc"
              :rules="rules76"
              label="Pd ctoz per piece"
              hint="0.000000"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="rhCtozPerPc"
              :rules="rules76"
              label="Rh ctoz per piece"
              hint="0.000000"
              type="number"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="ptCtozPerLb"
              :rules="rules76"
              label="Pt ctoz per lb"
              hint="0.000000"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="pdCtozPerLb"
              :rules="rules76"
              label="Pd ctoz per lb"
              hint="0.000000"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="rhCtozPerLb"
              :rules="rules76"
              label="Rh ctoz per lb"
              hint="0.000000"
              type="number"
            />
          </v-col>
        </v-row>

        <v-text-field
          v-model="scrapPerLb"
          :rules="rules76"
          label="Scrap Per lb"
          hint="0.000000"
          type="number"
        />
        <v-text-field
          v-model="carbonPercent"
          :rules="rules52"
          label="Carbon Percent"
          hint="000.00"
          type="number"
        />
        <v-text-field
          v-model="moisturePercent"
          :rules="rules52"
          label="Moisture Percent"
          hint="000.00"
          type="number"
        />
      </v-form>
    </v-card-text>
  </edit-dialog>
</template>

<script>

export default {
  name: 'ManualPpmDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    ppmCalculation: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      validData: true,
      loading: false,
      pt: this.ppmCalculation.pt_ppm,
      pd: this.ppmCalculation.pd_ppm,
      rh: this.ppmCalculation.rh_ppm,
      ptCtozPerPc: this.ppmCalculation.pt_ctoz_per_pc,
      pdCtozPerPc: this.ppmCalculation.pd_ctoz_per_pc,
      rhCtozPerPc: this.ppmCalculation.rh_ctoz_per_pc,
      ptCtozPerLb: this.ppmCalculation.pt_ctoz_per_lb,
      pdCtozPerLb: this.ppmCalculation.pd_ctoz_per_lb,
      rhCtozPerLb: this.ppmCalculation.rh_ctoz_per_lb,
      carbonPercent: this.ppmCalculation.carbon_percent,
      scrapPerLb: this.ppmCalculation.scrap_per_lb,
      moisturePercent: this.ppmCalculation.moisture_percent,
      rulesWhole: [
        x => x >= 0 || 'must be a positive number',
        x => !(x % 1 > 0) || 'must be a whole number'
      ],
      rules76: [
        x => x >= 0 || 'must be a positive number',
        x => !(x * 1000000 % 1 > 0) || 'may only have 6 decimals',
        x => String(Math.round(x)).length <= 1 || 'Maximum 1 digits to left of decimal'
      ],
      rules52: [
        x => x >= 0 || 'must be a positive number',
        x => !(x * 100 % 1 > 0) || 'may only have 2 decimals',
        x => String(Math.round(x)).length <= 3 || 'Maximum 3 digits to left of decimal'
      ]
    }
  },
  watch: {
    ppmCalculation () {
      this.pt = this.ppmCalculation.pt_ppm
      this.pd = this.ppmCalculation.pd_ppm
      this.rh = this.ppmCalculation.rh_ppm
      this.ptCtozPerPc = this.ppmCalculation.pt_ctoz_per_pc
      this.pdCtozPerPc = this.ppmCalculation.pd_ctoz_per_pc
      this.rhCtozPerPc = this.ppmCalculation.rh_ctoz_per_pc
      this.ptCtozPerLb = this.ppmCalculation.pt_ctoz_per_lb
      this.pdCtozPerLb = this.ppmCalculation.pd_ctoz_per_lb
      this.rhCtozPerLb = this.ppmCalculation.rh_ctoz_per_lb
      this.carbonPercent = this.ppmCalculation.carbon_percent
      this.scrapPerLb = this.ppmCalculation.scrap_per_lb
      this.moisturePercent = this.ppmCalculation.moisture_percent
    }
  },
  methods: {
    // Saves ppm calc with new options
    savePpmCalc () {
      let body = {
        id: this.ppmCalculation.id,
        pt_ppm: this.pt,
        pd_ppm: this.pd,
        rh_ppm: this.rh,
        pt_ctoz_per_pc: this.ptCtozPerPc,
        pd_ctoz_per_pc: this.pdCtozPerPc,
        rh_ctoz_per_pc: this.rhCtozPerPc,
        pt_ctoz_per_lb: this.ptCtozPerLb,
        pd_ctoz_per_lb: this.pdCtozPerLb,
        rh_ctoz_per_lb: this.rhCtozPerLb,
        carbon_percent: this.carbonPercent,
        scrap_per_lb: this.scrapPerLb,
        moisture_percent: this.moisturePercent
      }
      this.$store.dispatch('ppmCalculationStore/updatePpmCalculation', body)
      this.$emit('input', false)
    }
  }
}
</script>
