<template>
  <parent-tabs :tabs="filterTabs()" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CatalogParent',
  methods: {
    filterTabs () {
      return this.pageTabs.reduce((accu, i) => {
        if (i.can) accu.push(i)
        return accu
      }, [])
    }
  },
  computed: {
    ...mapGetters({
      can: 'can'
    }),
    pageTabs () {
      return [
        { id: 1, title: 'Catalog', route: '/catalog-list', can: this.can('view-catalogs') },
        { id: 3, title: 'Vehicle', route: '/catalog-vehicle-list', can: this.can('catalog-vehicle-list') },
        { id: 4, title: 'Photo Groups', route: '/photo-group-list', can: this.can('photo-group-list') },
        { id: 5, title: 'Create Catalog', route: '/create-catalog', can: this.can('create-catalogs') },
        { id: 6, title: 'Pricing', route: '/catalog-pricing', can: this.can('view-pricing') }
      ]
    }
  }
}
</script>
