<!--Tabbed page to display different pricing configuration settings-->
<template>
  <v-container>
    <div>
      <base-title title="Catalog Pricing" />
      <v-tabs
        background-color="accent-4"
        dark
      >
        <v-tabs-slider />
        <v-tab :href="`#tab-1`">
          Pricing Explained
        </v-tab>
        <v-tab :href="`#tab-2`">
          Market Rates
        </v-tab>
        <v-tab :href="`#tab-3`">
          Categories
        </v-tab>
        <v-tab-item
          :value="'tab-1'"
        >
          <pricing-example />
        </v-tab-item>
        <v-tab-item
          :value="'tab-2'"
        >
          <market-rates />
        </v-tab-item>
        <v-tab-item
          :value="'tab-3'"
        >
          <categories />
        </v-tab-item>
      </v-tabs>
    </div>
  </v-container>
</template>

<script>

import MarketRates from './Components/Pricing/MarketRates'
import Categories from './Components/Pricing/Categories'
import PricingExample from './Components/Pricing/PricingExample'
export default {
  name: 'CatalogPricing',
  components: { PricingExample, Categories, MarketRates }
}
</script>
