<template>
  <unauthorized
    :authorized="$store.getters.can('view-photo-groups')"
    message="Unauthorized to view Photo Groups"
  >
    <v-skeleton-loader
      type="title"
      v-if="!photoGroup"
    />
    <v-container v-else>
      <url-breadcrumbs
        :items="[
          ['catalog list', { name: 'catalog-list' }],
          ['photo group list', { name: 'photo-group-list' }],
          photoGroup.url
        ]"
      />
      <base-title :title="`Photo Group #${photoGroup.id}`" />
      <v-toolbar
        flat
      >
        <v-spacer />
        <v-btn
          color="accent"
          text
          @click="deletePhotoGroup"
        >
          delete
        </v-btn>
      </v-toolbar>
      <v-row>
        <v-col cols="6">
          <v-card>
            <converter-image-viewer
              :photo-group="photoGroup"
            />
            <v-card-title>
              {{ photoGroup.name }}
            </v-card-title>
            <v-card-subtitle>
              <em>{{ photoGroup.images.length }} Total Images</em>
            </v-card-subtitle>
            <v-card-text>
              Attached to Converters
              <v-list>
                <v-list-item
                  v-for="converter in converters"
                  :key="converter.id"
                >
                  <router-link :to="urlToRouteTo(converter.catalog)[1]">
                    {{ converter.id }} - {{ converter.code }}
                  </router-link>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </unauthorized>
</template>

<script>
import axios from 'axios'
import ConverterImageViewer from '@/base/components/ConverterImageViewer'

export default {
  name: 'PhotoGroup',
  components: { ConverterImageViewer },
  data: () => ({
    converters: []
  }),
  watch: {
    photoGroup (group) {
      if (!group) return
      const fields = ['url', 'id', 'catalog', 'code', 'notes'].join(',')
      const params = { picture_set: group.id, page_size: 0, fields }
      axios.get('/api/converters/', { params }).then(r => {
        if (r.status === 200) this.converters = r.data
      })
    }
  },
  computed: {
    // gets the Photo Group from the store
    photoGroup () {
      return this.$store.state['photoGroup'].items[this.$route.params.id]
    }
  },
  methods: {
    deletePhotoGroup () {
      if (confirm('Are you sure you want to delete this photo group')) {
        this.$store.dispatch('photoGroup/deletePhotoGroup', this.$route.params.id).then(() => {
          this.$router.push({ name: 'photo-group-list' })
          this.$store.commit('setSnackbarInfo', 'Deleted Photo Group')
        })
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('photoGroup/fetchPhotoGroup', this.$route.params.id)
  }
}
</script>
