var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-subtitle',[_c('strong',[_vm._v("Assays")])]),_c('v-data-table',{staticClass:"mx-4",attrs:{"headers":_vm.headers,"items":_vm.assays,"mobile-breakpoint":400,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.pt_ppm",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pt-color"},[_vm._v(" "+_vm._s(_vm._f("appendPpm")(_vm._f("addCommas")(_vm._f("trimDecimals")(item.pt_ppm))))+" ")])]}},{key:"item.pd_ppm",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pd-color"},[_vm._v(" "+_vm._s(_vm._f("appendPpm")(_vm._f("addCommas")(_vm._f("trimDecimals")(item.pd_ppm))))+" ")])]}},{key:"item.rh_ppm",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"rh-color"},[_vm._v(" "+_vm._s(_vm._f("appendPpm")(_vm._f("addCommas")(_vm._f("trimDecimals")(item.rh_ppm))))+" ")])]}},{key:"item.yield_lbs",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("appendLbs")(_vm._f("addCommas")(_vm._f("trimDecimals")(item.yield_lbs))))+" ")]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.$store.getters.can('delete-assays'),"color":"accent","icon":""},on:{"click":function($event){return _vm.deleteAssay(item)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }