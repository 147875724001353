var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('edit-dialog',{attrs:{"value":_vm.value,"headline-text":"Update Vehicle","accept-text":"Save","inputs-valid":!invalid && _vm.valid},on:{"input":function($event){return _vm.$emit('input', $event)},"accept":_vm.updateVehicle}},[_c('v-text-field',{attrs:{"readonly":"","label":"Make (readonly)","value":_vm.vehicle.make_text}}),_c('v-text-field',{attrs:{"readonly":"","label":"Model (readonly)","value":_vm.vehicle.model_text}}),_c('ValidationProvider',{attrs:{"rules":"length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Year","error-messages":errors},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"max:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Cylinders","error-messages":errors,"hint":"Should be a number"},model:{value:(_vm.cylinders),callback:function ($$v) {_vm.cylinders=$$v},expression:"cylinders"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"max:4|one_decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Engine Size Liters","hint":"Should be formatted 0.0","error-messages":errors},model:{value:(_vm.engineSize),callback:function ($$v) {_vm.engineSize=$$v},expression:"engineSize"}})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }