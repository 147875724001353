<template>
  <div>
    <v-chip
      class="ma-1"
      v-for="url in excludedSummaryUrls"
      :key="url"
      @click:close="$emit('clear', url)"
      close
    >
      {{ excludedSummariesCache[url] }}
    </v-chip>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ExcludedSummaryChips',
  props: {
    excludedSummaryUrls: {
      type: Array,
      required: true
    }
  },
  watch: {
    excludedSummaryUrls: {
      immediate: true,
      async handler (urls) {
        const unique = urls.filter(url => !this.excludedSummariesCache[url])
        if (unique.length < 1) return

        const params = {
          fields: 'url,shipment_summary,lot',
          id__in: unique.map(url => url.match(/(\d*)\/?$/gm)[0].replace('/', '')).join(','),
          page_size: 0
        }
        const response = await axios.get('/api/shipment_summary_lots/', { params })

        response.data.forEach(summary => {
          let name = ''
          if (summary.lot) name += summary.lot.name
          if (summary.shipment_summary) name += ' ' + summary.shipment_summary.id
          this.excludedSummariesCache[summary.url] = name
        })
        this.excludedSummariesCache = Object.assign({}, this.excludedSummariesCache)
      }
    }
  },
  data: function () {
    return {
      excludedSummariesCache: {}
    }
  }
}
</script>
