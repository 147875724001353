<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Name
        </v-list-item-title>
        {{ ppmCalculation.name }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Calculation Mode
          <info-tooltip>
            If the mode is set to filters/automatic the filters and lots cards will be show
          </info-tooltip>
        </v-list-item-title>
        {{ ppmCalculation.is_manual ? 'Manual' : 'Filters' }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Notes
        </v-list-item-title>
        {{ ppmCalculation.memo || '--' }}
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'PpmMeta',
  props: {
    ppmCalculation: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
