<!--
Allows users to bulk update catalog entries!
Requested by steve
-->

<template>
  <div>
    <edit-dialog
      v-model="dialog"
      headline-text="Update Catalog"
      accept-text="UPDATE ALL ENTRIES"
      :inputs-valid="confirmText === expectedText && converterGroup"
      @accept="confirmUpdate"
    >
      <v-select
        v-model="converterGroup"
        :items="converterGroupOptions"
        label="Converter Groups"
        item-text="name"
        item-value="id"
        clearable
      />
      <p class="text-h5">
        Before you can proceed to make this change please enter <code>{{ expectedText }}</code> into the
        box below to confirm that you understand this is a <strong>BULK CHANGE</strong> that can not be undone.
      </p>
      <v-text-field
        v-model="confirmText"
        label="Confirmation"
        :placeholder="expectedText"
      />
    </edit-dialog>
    <text-fab
      bottom
      right
      fixed
      icon-text="mdi-alert-circle-outline"
      color="error"
      @click="dialog = true"
    >
      Bulk Update
    </text-fab>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'BulkUpdateCatalog',
  data: () => ({
    dialog: false,
    confirmText: '',
    converterGroup: null,
    converterGroupOptions: [],
    converterGroupOptionsFetched: false
  }),
  computed: {
    expectedText () {
      return 'do the bulk update'
    }
  },
  watch: {
    dialog (opened) {
      if (opened) this.confirmText = ''
      this.fetchConverterGroupOptions()
    }
  },
  methods: {
    async fetchConverterGroupOptions () {
      // Lazy load only once
      if (this.converterGroupOptionsFetched) return
      // Specialized for Converter Groups
      try {
        const res = await axios.get('/api/converter_groups/', { params: { page_size: 0, fields: 'id,name' } })
        if (res.status === 200) this.converterGroupOptions = res.data
      } catch {
        this.$store.commit('setSnackbarWarning', 'Unable to fetch converter groups')
      }
      this.converterGroupOptionsFetched = true
    },
    confirmUpdate () {
      if (confirm('Are you sure? This action can not be undone.')) {
        this.$emit('confirm', { converter_group_id: this.converterGroup })
      }
    }
  }
}
</script>
