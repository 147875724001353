var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.getters.can('create-assays'))?_c('div',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",[_c('v-btn',_vm._g({},on),[_vm._v(" Add Assay ")])],{"on":on})]}}],null,true),model:{value:(_vm.addAssayDialog),callback:function ($$v) {_vm.addAssayDialog=$$v},expression:"addAssayDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Add an Assay "),_c('v-spacer'),_c('base-button',{attrs:{"icon-bool":"","icon":"mdi-close"},on:{"clicked":function($event){_vm.addAssayDialog = false}}})],1),_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Platinum PPM","error-messages":_vm.formatErrors(errors)},model:{value:(_vm.assay.pt),callback:function ($$v) {_vm.$set(_vm.assay, "pt", $$v)},expression:"assay.pt"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Palladium PPM","error-messages":_vm.formatErrors(errors)},model:{value:(_vm.assay.pd),callback:function ($$v) {_vm.$set(_vm.assay, "pd", $$v)},expression:"assay.pd"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Rhodium PPM","error-messages":_vm.formatErrors(errors)},model:{value:(_vm.assay.rh),callback:function ($$v) {_vm.$set(_vm.assay, "rh", $$v)},expression:"assay.rh"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Yield Pounds","error-messages":_vm.formatErrors(errors)},model:{value:(_vm.assay.yieldPounds),callback:function ($$v) {_vm.$set(_vm.assay, "yieldPounds", $$v)},expression:"assay.yieldPounds"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","disabled":!invalid && !_vm.addingAssay && _vm.oneGreaterThanZero},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":invalid || _vm.addingAssay || !_vm.oneGreaterThanZero,"loading":_vm.addingAssay},on:{"click":_vm.addAssay}},[_vm._v(" Add ")])],1)]}}],null,true)},[(!_vm.oneGreaterThanZero)?_c('span',[_vm._v(" One PPM value must be greater than 0 ")]):_c('span',[_vm._v(" All fields must be valid ")])])],1)],1)]}}],null,false,1874580110)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }