<!--
Edit Vehicle Dialog
:vehicle [vehicle model object]
-->
<template>
  <ValidationObserver v-slot:="{ invalid }">
    <edit-dialog
      :value="value"
      @input="$emit('input', $event)"
      headline-text="Update Vehicle"
      accept-text="Save"
      :inputs-valid="!invalid && valid"
      @accept="updateVehicle"
    >
      <v-text-field
        readonly
        label="Make (readonly)"
        :value="vehicle.make_text"
      />
      <v-text-field
        readonly
        label="Model (readonly)"
        :value="vehicle.model_text"
      />
      <ValidationProvider
        rules="length:4"
        v-slot="{ errors }"
      >
        <v-text-field
          type="number"
          label="Year"
          v-model="year"
          :error-messages="errors"
        />
      </ValidationProvider>
      <ValidationProvider
        rules="max:2"
        v-slot="{ errors }"
      >
        <v-text-field
          type="number"
          label="Cylinders"
          v-model="cylinders"
          :error-messages="errors"
          hint="Should be a number"
        />
      </ValidationProvider>
      <ValidationProvider
        rules="max:4|one_decimal"
        v-slot="{ errors }"
      >
        <v-text-field
          type="number"
          label="Engine Size Liters"
          v-model="engineSize"
          hint="Should be formatted 0.0"
          :error-messages="errors"
        />
      </ValidationProvider>
    </edit-dialog>
  </ValidationObserver>
</template>

<script>

import axios from 'axios'

export default {
  name: 'ModifyVehicleDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    vehicle: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    year: '',
    cylinders: '',
    engineSize: ''
  }),
  watch: {
    value: {
      immediate: true,
      handler (opened) {
        if (!opened) return
        this.year = this.vehicle.year
        this.cylinders = this.vehicle.cylinders
        this.engineSize = this.vehicle.engine_size_liters
      }
    }
  },
  computed: {
    /**
     * Returns true if one value is set
     * @return {boolean}
     */
    valid () {
      if (this.year) return true
      if (this.cylinders) return true
      return !!this.engineSize
    }
  },
  methods: {
    async updateVehicle () {
      if (!this.valid) {
        this.$store.commit('setSnackbarWarning', 'Vehicle references require at least one detail')
        return
      }
      let payload = {}
      if (this.year) payload.year = this.year
      if (this.cylinders) payload.cylinders = this.cylinders
      if (this.engineSize) payload.engine_size_liters = this.engineSize
      const r = await axios.patch(this.vehicle.url, payload)
      if (r.status === 200) {
        this.$store.commit('setSnackbarSuccess', `Vehicle has been updated #${this.vehicle.id}`)
        this.$emit('updated', r.data)
        this.$emit('input', false)
      } else {
        this.$store.commit('setSnackbarError', `Unable to update vehicle #${this.vehicle.id}`)
      }
    }
  }
}
</script>
