<template>
  <unauthorized
    :authorized="$store.getters.can('catalog-vehicle-list')"
    message="Unauthorized to view Photo Groups"
  >
    <v-container fluid>
      <base-title title="Vehicles" />
      <search-filters
        v-model="search"
        :chips="chips"
        @clearChip="clearChipProp"
        @clearFields="clearFields"
        @submitSearch="routePage"
      >
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              label="ID"
              v-model="id"
              type="number"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              label="Make"
              v-model="make"
              clearable
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              label="Model"
              v-model="model"
              clearable
            />
          </v-col>
        </v-row>
      </search-filters>
      <result-pagination
        v-if="pagination"
        v-model="pagination.page"
        :pages="Math.ceil(totalCount / 20)"
      />
      <v-data-table
        multi-sort
        :headers="headers"
        :items="resultList"
        :server-items-length="totalCount"
        :options.sync="pagination"
        :footer-props="{ 'items-per-page-options': [20] }"
        @page-count="pageCount = $event"
        hide-default-footer
      >
        <template v-slot:item.id="{ item }">
          <v-btn
            small
            @click="selectVehicle(item)"
          >
            {{ item.id }}
          </v-btn>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDate }}
        </template>
        <template v-slot:item.converters="{ item }">
          <v-icon
            color="success lighten-1"
            v-if="item.converters.length"
          >
            mdi-circle
          </v-icon>
        </template>
      </v-data-table>
      <result-pagination
        v-if="pagination"
        v-model="pagination.page"
        :pages="Math.ceil(totalCount / 20)"
      />
      <upload-video-dialog
        @addVideo="routeToLab"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            fixed
            bottom
            right
            fab
            color="primary"
            @click="newDialog = !newDialog"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
      </upload-video-dialog>
    </v-container>
    <modify-vehicle-dialog
      v-if="editVehicle"
      v-model="updateDialog"
      :vehicle="editVehicle"
      @updated="replaceResult"
    />
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import axios from 'axios'
import ResultPagination from '@/base/components/ResultPagination'
import SearchFilters from '@/base/filter/SearchFilters'
import ModifyVehicleDialog from '@/views/CatalogVehicle/ModifyVehicleDialog'

export default {
  mixins: [chipsMixin, paginationMixin],
  name: 'CatalogVehicleList',
  components: { ModifyVehicleDialog, SearchFilters, ResultPagination },
  data: function () {
    return {
      newDialog: false,
      updateDialog: false,
      pageName: 'catalog-vehicle-list',
      editVehicle: null,

      headers: [
        { text: 'id', value: 'id' },
        { text: 'Make', value: 'make_text', sortable: false },
        { text: 'Model', value: 'model_text', sortable: false },
        { text: 'Year', value: 'year' },
        { text: 'Cylinders', value: 'cylinders' },
        { text: 'Engine Size', value: 'engine_size_liters' },
        { text: 'Misc', value: 'misc' },
        { text: 'Has Converter', value: 'converters' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Make', prop: 'make', type: String, query: 'make_model__make__icontains' },
        { name: 'Model', prop: 'model', type: String, query: 'make_model__model__icontains' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    replaceResult (vehicle) {
      this.resultList = this.resultList.map(r => r.id === vehicle.id ? vehicle : r)
    },
    selectVehicle (vehicle) {
      this.editVehicle = vehicle
      this.updateDialog = true
    },
    routeToLab (result) {
      this.$router.push({ name: 'lab-video', params: { id: result.id } })
    },
    // Creates and submits query parameters
    async executeSearch () {
      const params = this.getQueryParams()
      params.fields = [
        'id',
        'make_text',
        'model_text',
        'year',
        'converters',
        'cylinders',
        'engine_size_liters',
        'misc'
      ].join(',')
      axios.get('/api/vehicles/', { params }).then(this.setResponseResults)
    },
    vehicleSelected (vehicle) {
      this.$store.commit('setSnackbarSuccess', `Vehicle Created/Referenced #${vehicle.id}`)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
