<template>
  <v-dialog
    :value="value"
    width="550"
    height="500"
    @input="$emit('input', $event)"
  >
    <v-toolbar
      dense
      dark
    >
      <v-toolbar-title>
        OZ Calculator
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          icon
          @click="clearAndCancel"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
            <v-text-field
              label="Converter Pieces"
              v-model.number="pieces"
              suffix="pcs"
              type="number"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
            <v-text-field
              label="Pounds of Dust"
              v-model.number="pounds"
              suffix="lbs"
              type="number"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <paginated-autocomplete
              label="Smelter"
              v-model="smelter"
              item-text="name"
              return-object
              base-url="/api/smelters/"
            />
          </v-col>
        </v-row>
        <div class="subtitle-2 text-center">
          Return Rate
        </div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td v-if="$vuetify.breakpoint.smAndUp" />
                <th>
                  Platinum
                </th>
                <th>
                  Palladium
                </th>
                <th>
                  Rhodium
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="$vuetify.breakpoint.smAndUp" />
                <td class="pt-color">
                  {{ smelter ? smelter.pt_return_rate : 0 | appendPercent }}
                </td>
                <td class="pd-color">
                  {{ smelter ? smelter.pd_return_rate : 0 | appendPercent }}
                </td>
                <td class="rh-color">
                  {{ smelter ? smelter.rh_return_rate : 0 | appendPercent }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="subtitle-2 text-center">
          Contained OZ
        </div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td v-if="$vuetify.breakpoint.smAndUp" />
                <th>
                  Platinum
                </th>
                <th>
                  Palladium
                </th>
                <th>
                  Rhodium
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="$vuetify.breakpoint.smAndUp" />
                <td class="pt-color">
                  {{ (pieces*ppmCalculation.pt_ctoz_per_pc) + (pounds*ppmCalculation.pt_ctoz_per_lb) | toFixed(3) }}
                </td>
                <td class="pd-color">
                  {{ (pieces*ppmCalculation.pd_ctoz_per_pc) + (pounds*ppmCalculation.pd_ctoz_per_lb) | toFixed(3) }}
                </td>
                <td class="rh-color">
                  {{ (pieces*ppmCalculation.rh_ctoz_per_pc) + (pounds*ppmCalculation.rh_ctoz_per_lb) | toFixed(3) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="subtitle-2 text-center">
          Returnable OZ
        </div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th v-if="$vuetify.breakpoint.smAndUp" />
                <th>
                  Platinum
                </th>
                <th>
                  Palladium
                </th>
                <th>
                  Rhodium
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="$vuetify.breakpoint.smAndUp" />
                <td class="pt-color">
                  {{ ((pieces*ppmCalculation.pt_ctoz_per_pc) + (pounds*ppmCalculation.pt_ctoz_per_lb)) * (smelter ? smelter.pt_return_rate : 0) | toFixed(3) }}
                </td>
                <td class="pd-color">
                  {{ ((pieces*ppmCalculation.pd_ctoz_per_pc) + (pounds*ppmCalculation.pd_ctoz_per_lb)) * (smelter ? smelter.pd_return_rate : 0) | toFixed(3) }}
                </td>
                <td class="rh-color">
                  {{ ((pieces*ppmCalculation.rh_ctoz_per_pc) + (pounds*ppmCalculation.rh_ctoz_per_lb)) * (smelter ? smelter.rh_return_rate : 0) | toFixed(3) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'CalculatorDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    ppmCalculation: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    smelter: null,
    pieces: 0,
    pounds: 0,
    loading: false
  }),
  methods: {
    clearAndCancel () {
      this.pieces = 0
      this.pounds = 0
      this.smelter = null
      this.$emit('input', false)
    }
  }
}
</script>
