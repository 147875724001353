<template>
  <div v-if="$store.getters.can('create-assays')">
    <v-dialog
      v-model="addAssayDialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <slot
          name="activator"
          :on="on"
        >
          <v-btn
            v-on="on"
          >
            Add Assay
          </v-btn>
        </slot>
      </template>
      <v-card>
        <v-card-title>
          Add an Assay
          <v-spacer />
          <base-button
            icon-bool
            icon="mdi-close"
            @clicked="addAssayDialog = false"
          />
        </v-card-title>
        <ValidationObserver
          v-slot="{ invalid }"
          ref="validationObserver"
        >
          <v-card-text>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <ValidationProvider
                  rules="required|min_value:0"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Platinum PPM"
                    v-model="assay.pt"
                    :error-messages="formatErrors(errors)"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <ValidationProvider
                  rules="required|min_value:0"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Palladium PPM"
                    v-model="assay.pd"
                    :error-messages="formatErrors(errors)"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <ValidationProvider
                  rules="required|min_value:0"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Rhodium PPM"
                    v-model="assay.rh"
                    :error-messages="formatErrors(errors)"
                  />
                </ValidationProvider>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                <ValidationProvider
                  rules="required|min_value:0"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Yield Pounds"
                    v-model="assay.yieldPounds"
                    :error-messages="formatErrors(errors)"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer />
              <v-tooltip
                bottom
                :disabled="!invalid && !addingAssay && oneGreaterThanZero"
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      :disabled="invalid || addingAssay || !oneGreaterThanZero"
                      :loading="addingAssay"
                      @click="addAssay"
                    >
                      Add
                    </v-btn>
                  </span>
                </template>
                <span v-if="!oneGreaterThanZero"> One PPM value must be greater than 0 </span>
                <span v-else> All fields must be valid </span>
              </v-tooltip>
            </v-card-actions>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddAssay',
  props: {
    addingAssay: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    addAssayDialog: false,
    wasLoading: false,
    assay: {
      pt: 0,
      pd: 0,
      rh: 0,
      yieldPounds: 0
    }
  }),
  watch: {
    addingAssay () {
      if (this.addingAssay) this.wasLoading = true
      if (!this.addingAssay && this.wasLoading) this.addAssayDialog = false
    }
  },
  computed: {
    oneGreaterThanZero () {
      return this.assay.pt > 0 || this.assay.pd > 0 || this.assay.rh > 0
    }
  },
  methods: {
    /**
     * Create a new assay by sending the data to the parent
     */
    addAssay () {
      const body = {
        pt_ppm: this.assay.pt,
        pd_ppm: this.assay.pd,
        rh_ppm: this.assay.rh,
        yield_lbs: this.assay.yieldPounds
      }
      this.$emit('addAssay', body)
    },
    /**
     * Provide users with more readable error messages
     * @param {Array[String]} errors
     * @return {String}
     */
    formatErrors (errors) {
      let error = errors[0]
      if (error === 'min_value') return `Cannot be less than 0`
      return error
    }
  }
}
</script>
