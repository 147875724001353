var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.createConverter($event)}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Code","error-messages":errors},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Converter Group","items":_vm.converterGroups,"error-messages":errors,"item-text":"name","item-value":"url"},model:{value:(_vm.converterGroup),callback:function ($$v) {_vm.converterGroup=$$v},expression:"converterGroup"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('paginated-autocomplete',{attrs:{"label":"Make/Models","search-field":"make","item-text":"friendly_name","item-value":"id","multiple":"","base-url":"/api/make_models/","error-messages":errors},model:{value:(_vm.makeModels),callback:function ($$v) {_vm.makeModels=$$v},expression:"makeModels"}})]}}],null,true)}),_c('v-textarea',{attrs:{"label":"Notes (Optional)"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),_c('base-button',{attrs:{"auto-center":"","disabled":invalid || _vm.loading,"loading":_vm.loading,"type":"submit","label":"Add"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }