<template>
  <v-card>
    <v-card-subtitle>
      <strong>Assays</strong>
    </v-card-subtitle>
    <v-data-table
      class="mx-4"
      :headers="headers"
      :items="assays"
      :mobile-breakpoint="400"
      hide-default-footer
      disable-sort
    >
      <template v-slot:item.pt_ppm="{ item }">
        <span class="pt-color">
          {{ item.pt_ppm | trimDecimals | addCommas | appendPpm }}
        </span>
      </template>
      <template v-slot:item.pd_ppm="{ item }">
        <span class="pd-color">
          {{ item.pd_ppm | trimDecimals | addCommas | appendPpm }}
        </span>
      </template>
      <template v-slot:item.rh_ppm="{ item }">
        <span class="rh-color">
          {{ item.rh_ppm | trimDecimals | addCommas | appendPpm }}
        </span>
      </template>
      <template v-slot:item.yield_lbs="{ item }">
        {{ item.yield_lbs | trimDecimals | addCommas | appendLbs }}
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn
          :disabled="!$store.getters.can('delete-assays')"
          color="accent"
          icon
          @click="deleteAssay(item)"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AssaySection',
  props: {
    assays: {
      type: Array,
      required: false,
      default: null
    },
    manageable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    headers () {
      let h = [
        { text: 'ID', value: 'id' },
        { text: 'PT', value: 'pt_ppm' },
        { text: 'PD', value: 'pd_ppm' },
        { text: 'RH', value: 'rh_ppm' },
        { text: 'Yield', value: 'yield_lbs' }
      ]
      if (this.manageable) h.push({ text: 'delete', value: 'delete' })
      return h
    }
  },
  methods: {
    deleteAssay (assay) {
      axios.delete(assay.url).then(() => {
        this.$store.commit('setSnackbarInfo', `Deleted assay #${assay.id}`)
        this.$emit('assayDeleted')
      })
    }
  }
}
</script>
