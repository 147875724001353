<template>
  <unauthorized
    :authorized="$store.getters.can('create-catalogs')"
    message="Unauthorized to create catalog items"
  >
    <v-container>
      <base-title>
        {{ currentTitle }}
      </base-title>
      <v-stepper
        alt-labels
        :value="step"
      >
        <v-stepper-header>
          <v-stepper-step step="1">
            Create Catalog
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2">
            Add Converter*
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-sheet class="px-4">
        <v-window v-model="step">
          <v-window-item :value="1">
            <add-catalog-item
              :catalog-types="catalogTypes"
              @createCatalog="createCatalog"
            />
          </v-window-item>
          <v-window-item :value="2">
            <add-converter
              :converter-types="converterTypes"
              @createConverter="createConverter"
            />
          </v-window-item>
        </v-window>
      </v-sheet>
    </v-container>
  </unauthorized>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddCatalogItem from './Components/AddCatalogItem'
import AddConverter from './Components/AddConverter'

export default {
  name: 'CreateCatalog',
  components: {
    AddCatalogItem,
    AddConverter
  },
  data: () => ({
    loading: true,
    step: 1
  }),
  computed: {
    ...mapGetters({
      converterTypes: 'getConverterTypes',
      catalogTypes: 'catalogStore/catalogTypes',
      catalog: 'catalogStore/catalog'
    }),
    /**
     * Change the card title based on the creation step
     */
    currentTitle () {
      switch (this.step) {
        case 1: return 'Create Catalog Item'
        case 2: return 'Add Converter'
        default: return 'Catalog Item Created!'
      }
    }
  },
  methods: {
    ...mapActions({
      fetchConverterGroups: 'converterStore/fetchConverterGroups'
    }),
    /**
     * Create a new catalog
     * @param {Object} body
     * @return {Integer}
     */
    createCatalog (body) {
      this.$store.dispatch('catalogStore/createCatalog', body).then(() => {
        if (body.material_type === 'CV') {
          this.step++
        } else {
          this.$router.push({ path: `/catalog/${this.catalog.id}` })
        }
      })
    },
    /**
     * Create a new converter
     * @param {Object} body
     */
    createConverter (body) {
      const converter = {
        catalog: this.catalog.url,
        code: body.code,
        converter_group: body.converterGroup,
        notes: body.notes,
        vehicle_set: []
      }
      const params = { converter, makeModels: body.makeModels }
      this.$store.dispatch('converterStore/createConverter', params).then(() => {
        this.$router.push({ path: `/catalog/${this.catalog.id}` })
      })
    }
  },
  mounted () {
    this.fetchConverterGroups()
  }
}
</script>
