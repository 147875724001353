<!--A page that queries ConverterGroups and their primary Catalog entries for manual adjustments-->
<template>
  <v-card
    flat
    tile
  >
    <v-form
      v-model="valid"
      @submit.prevent="commitCategoryChanges"
    >
      <v-card-text>
        <p>
          Click on a <v-btn x-small>
            field
          </v-btn> to update it. Once a field is updated
          click on <v-btn x-small>
            save categories
          </v-btn> to commit the changes and see the price update.
        </p>
        <p>
          Market rates saved in <strong>USD</strong> on this page.
        </p>
        <p>
          Prices listed reflect the pricing based on current market rate and are re-calculated when categories are saved.
          <ul>
            <li>Yield/PT/PD/RH rates are adjusted manually</li>
            <li>Percentage is applied to Category Pricing, but not the price of individual converters</li>
          </ul>
        </p>
        <p>
          The Fields <em>name/yield/pt/pd/rh/percentages</em> may be modified.
        </p>
        <v-data-table
          :items-per-page="10"
          :headers="headers"
          :items="items"
        >
          <template v-slot:item.name="props">
            <v-edit-dialog>
              {{ props.item.name }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.name"
                  label="Name"
                  counter
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.nav_code="props">
            <v-edit-dialog>
              {{ props.item.nav_code }}
              <template v-slot:input>
                <v-text-field
                  :rules="navRules"
                  v-model="props.item.nav_code"
                  label="Nav Code"
                  type="number"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.category="props">
            <v-edit-dialog>
              {{ props.item.category | yardCategory }}
              <template v-slot:input>
                <v-select
                  v-model="props.item.category"
                  :items="$store.getters['converterTypes']"
                  label="Category"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.yield_lbs="props">
            <v-edit-dialog>
              {{ props.item.yield_lbs }}
              <template v-slot:input>
                <v-text-field
                  :rules="yieldRules"
                  v-model="props.item.yield_lbs"
                  label="Yield"
                  type="number"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.pt_ppm="props">
            <v-edit-dialog>
              {{ props.item.pt_ppm }}
              <template v-slot:input>
                <v-text-field
                  :rules="wholePositiveNumber"
                  v-model="props.item.pt_ppm"
                  label="Platinum"
                  type="number"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.pd_ppm="props">
            <v-edit-dialog>
              {{ props.item.pd_ppm }}
              <template v-slot:input>
                <v-text-field
                  :rules="wholePositiveNumber"
                  v-model="props.item.pd_ppm"
                  label="Palladium"
                  type="number"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.rh_ppm="props">
            <v-edit-dialog>
              {{ props.item.rh_ppm }}
              <template v-slot:input>
                <v-text-field
                  :rules="wholePositiveNumber"
                  v-model="props.item.rh_ppm"
                  label="Rhodium"
                  type="number"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.percentage_multiplier="props">
            <v-edit-dialog>
              {{ props.item.percentage_multiplier }}
              <template v-slot:input>
                <v-text-field
                  :rules="percentRules"
                  v-model="props.item.percentage_multiplier"
                  label="Percentage"
                  type="number"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.max_price="props">
            <v-edit-dialog>
              {{ props.item.max_price }}
              <template v-slot:input>
                <v-text-field
                  :rules="wholePositiveNumber"
                  v-model="props.item.max_price"
                  label="Max Price"
                  type="number"
                />
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.converter_max_price="props">
            <v-edit-dialog>
              {{ props.item.converter_max_price }}
              <template v-slot:input>
                <v-text-field
                  :rules="wholePositiveNumber"
                  v-model="props.item.converter_max_price"
                  label="Converter Max Price"
                  type="number"
                />
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
        <p class="text-center">
          <span
            v-if="!valid"
            class="red--text"
          >
            <em>
              One of your inputs is invalid
            </em>
          </span>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="(!changed || !valid)"
          type="submit"
          color="success"
          text
        >
          Save Categories
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Categories',
  data: () => ({
    valid: true,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Nav', value: 'nav_code' },
      { text: 'Category', value: 'category' },
      { text: 'Yield', value: 'yield_lbs' },
      { text: 'Platinum', value: 'pt_ppm' },
      { text: 'Palladium', value: 'pd_ppm' },
      { text: 'Rhodium', value: 'rh_ppm' },
      { text: 'Percentage', value: 'percentage_multiplier' },
      // { text: 'MM Cap (KK)', value: 'max_price' },
      // { text: 'CV Cap (KK)', value: 'converter_max_price' },
      { text: 'Total Value', value: 'price' }
    ],
    itemString: '',
    items: [],
    changed: false,
    wholePositiveNumber: [
      value => (value === 0 || !!value) || 'Required',
      value => (value && !isNaN(value)) || 'Must be a Number',
      value => (value && value < 100000.00) || 'Exceeded Max Value',
      value => (value && !(value % 1 > 0)) || 'Must be a Whole Number',
      value => (value && value >= 0) || 'Must be a Positive Number'
    ],
    yieldRules: [
      value => (value === 0 || !!value) || 'Required',
      value => (value && !isNaN(value)) || 'Must be a Number',
      value => (value && value < 100.00) || 'Exceeded Max Value',
      value => (value && value >= 0) || 'Must be a Positive Number',
      value => (value && (String(value).split('.').length !== 2 || String(value).split('.')[1].length < 3)) || 'Max Decimals is 2'
    ],
    percentRules: [
      value => (value === 0 || !!value) || 'Required',
      value => (value && !isNaN(value)) || 'Must be a Number',
      value => (value && value <= 1.00) || 'Exceeded Max Value',
      value => (value && value >= 0) || 'Must be a Positive Number',
      value => (value && (String(value).split('.').length !== 2 || String(value).split('.')[1].length < 3)) || 'Max Decimals is 2'
    ],
    navRules: [
      value => (value === 0 || !!value) || 'Required',
      value => (value && !isNaN(value)) || 'Must be a Number',
      value => (value && value >= 0) || 'Must be a Greater or Equal to Zero'
    ]
  }),
  watch: {
    items: {
      deep: true,
      handler () {
        this.changed = this.itemString !== JSON.stringify(this.items)
      }
    }
  },
  methods: {
    // Sets the items based on response data
    setItems (response) {
      this.itemString = JSON.stringify(response.data)
      this.items = response.data
    },
    fetchConverterGroups () {
      axios.get('/api/converter_groups/configure/').then(this.setItems)
    },
    commitCategoryChanges () {
      axios.patch('/api/converter_groups/configure/', this.items).then(this.setItems)
    }
  },
  mounted () {
    if (!this.items.length) this.fetchConverterGroups()
  }
}
</script>
