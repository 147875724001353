var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('catalog-vehicle-list'),"message":"Unauthorized to view Photo Groups"}},[_c('v-container',{attrs:{"fluid":""}},[_c('base-title',{attrs:{"title":"Vehicles"}}),_c('search-filters',{attrs:{"chips":_vm.chips},on:{"clearChip":_vm.clearChipProp,"clearFields":_vm.clearFields,"submitSearch":_vm.routePage},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"ID","type":"number","clearable":""},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Make","clearable":""},model:{value:(_vm.make),callback:function ($$v) {_vm.make=$$v},expression:"make"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Model","clearable":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)],1),(_vm.pagination)?_c('result-pagination',{attrs:{"pages":Math.ceil(_vm.totalCount / 20)},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e(),_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.resultList,"server-items-length":_vm.totalCount,"options":_vm.pagination,"footer-props":{ 'items-per-page-options': [20] },"hide-default-footer":""},on:{"update:options":function($event){_vm.pagination=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.selectVehicle(item)}}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.converters",fn:function(ref){
var item = ref.item;
return [(item.converters.length)?_c('v-icon',{attrs:{"color":"success lighten-1"}},[_vm._v(" mdi-circle ")]):_vm._e()]}}])}),(_vm.pagination)?_c('result-pagination',{attrs:{"pages":Math.ceil(_vm.totalCount / 20)},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e(),_c('upload-video-dialog',{on:{"addVideo":_vm.routeToLab},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"fixed":"","bottom":"","right":"","fab":"","color":"primary"},on:{"click":function($event){_vm.newDialog = !_vm.newDialog}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])})],1),(_vm.editVehicle)?_c('modify-vehicle-dialog',{attrs:{"vehicle":_vm.editVehicle},on:{"updated":_vm.replaceResult},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }