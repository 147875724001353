<template>
  <ValidationObserver
    v-slot="{ invalid }"
    ref="validationObserver"
  >
    <v-row>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
        <ValidationProvider
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Name"
            v-model="name"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
        <ValidationProvider
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Description"
            v-model="description"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
        <ValidationProvider
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            label="Material Type"
            v-model="type"
            :items="catalogTypes"
            item-text="text"
            item-value="value"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
        <ValidationProvider
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            label="Unit of Measure"
            v-model="unitOfMeasure"
            :items="unitTypes"
            item-text="text"
            item-value="value"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tooltip
          bottom
          :disabled="type !== 'CV'"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-text-field
                :disabled="type === 'CV'"
                label="Price"
                v-model="price"
              />
            </span>
          </template>
          <span>Converters do not require a manual price</span>
        </v-tooltip>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" />
    </v-row>
    <base-button
      auto-center
      :disabled="invalid || loading"
      @clicked="createCatalog"
      :loading="loading"
      label="Create"
    />
  </ValidationObserver>
</template>

<script>
export default {
  name: 'AddCatalogItem',
  props: {
    catalogTypes: { type: Array, required: true }
  },
  data: () => ({
    name: '',
    description: '',
    type: 'CV',
    unitOfMeasure: 'PCS',
    price: 0,
    loading: false
  }),
  watch: {
    // auto switch to pcs if converter
    type (newValue) {
      if (newValue === 'CV') this.unitOfMeasure = 'PCS'
    }
  },
  computed: {
    // only offer pcs if converter
    unitTypes () {
      const pcs = { text: 'Pieces', value: 'PCS' }
      if (this.type === 'CV') return [pcs]
      return [pcs, { text: 'Pounds', value: 'LBS' }]
    }
  },
  methods: {
    createCatalog (catalogItem) {
      const body = {
        name: this.name,
        description: this.description,
        material_type: this.type,
        unit_of_measure: this.unitOfMeasure
      }
      if (this.type !== 'CV') body.price = this.price
      this.$emit('createCatalog', body)
      this.loading = true
    }
  }
}
</script>
