<template>
  <div>
    <result-pagination
      v-model="options.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="items"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="catalog"
        />
      </template>
      <template v-slot:item.converter="{ item }">
        <div v-if="item.converter && (item.converter.static_picture_set || item.converter.picture_set)">
          <v-sheet
            max-width="150"
            max-height="150"
          >
            <converter-image-viewer
              :photo-group="item.converter.static_picture_set || item.converter.picture_set"
            />
          </v-sheet>
        </div>
        <p v-else>
          No Image
        </p>
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.name | filterNA }}
      </template>
      <template v-slot:item.converter__cat_name="{ item }">
        <span v-if="item.converter">
          {{ item.converter.cat_name | filterNA }}
        </span>
      </template>
      <template v-slot:item.converter__converter_group__name="{ item }">
        <span v-if="item.converter">
          {{ item.converter.converter_group_name }}
        </span>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
      <template v-slot:item.notes="{ item }">
        {{ item.converter | filterObject('notes') | filterNA }}
      </template>
      <template v-slot:item.description="{ item }">
        {{ item.description | filterNA }}
      </template>
      <template v-slot:item.price="{ item }">
        <span v-if="item.converter">
          <fx-tag
            :fx="item.converter.base_price"
          />
        </span>
        <span v-else-if="item.price">
          <fx-tag
            :fx="item.price_fx"
            :value="item.price"
            :cur="item.fx_currency"
          />
        </span>
        <span v-else>
          --
        </span>
      </template>
    </v-data-table>
    <result-pagination
      v-model="options.page"
      :pages="Math.ceil(totalCount / 20)"
    />
  </div>
</template>

<script>
import ResultPagination from '@/base/components/ResultPagination'
import ConverterImageViewer from '@/base/components/ConverterImageViewer'
export default {
  name: 'CatalogAdvancedSearchTable',
  components: { ConverterImageViewer, ResultPagination },
  props: {
    initOptions: { type: Object, required: true },
    totalCount: { type: Number, default: 0 },
    items: { type: Array, required: true }
  },
  data: function () {
    return { options: this.initOptions }
  },
  watch: {
    // observes and updated options
    options: function (updated, previous) {
      if (previous !== null) this.$emit('updated', updated)
    }
  },
  computed: {
    headers () {
      let headers = []
      headers.push({ text: 'ID', value: 'id' })
      headers.push({ text: 'Preview', value: 'converter' })
      headers.push({ text: 'Name', value: 'name' })
      headers.push({ text: 'Cat Name', value: 'converter__cat_name', sortable: false })
      headers.push({ text: 'Type', value: 'material_type' })
      headers.push({ text: 'Cv. Group', value: 'converter__converter_group__name', sortable: false })
      // if (this.$store.getters.can('view-assays')) {
      //   headers.push({ text: 'Yield', value: 'converter__yield_lbs' })
      //   headers.push({ text: 'PT', value: 'converter__pt_ppm' })
      //   headers.push({ text: 'PD', value: 'converter__pd_ppm' })
      //   headers.push({ text: 'RH', value: 'converter__rh_ppm' })
      // }
      // headers.push({ text: 'Notes', value: 'notes' })
      headers.push({ text: 'Description', value: 'description' })
      headers.push({ text: 'Price', value: 'price', sortable: false, align: 'right' })
      return headers
    }
  }
}
</script>
