<template>
  <edit-dialog
    max-width="1000"
    :value="value"
    headline-text="Edit Converter"
    @input="$emit('input', $event)"
    @accept="updateConverter"
  >
    <v-form
      v-if="$store.getters.can('update-catalog')"
      v-model="valid"
      @submit.prevent="updateConverter"
    >
      <ValidationObserver
        v-slot="{ invalid }"
        ref="validationObserver"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <!-- No friendly name if pictureSet not set -->
            <search-autocomplete
              v-model="pictureSet"
              label="Lookup Image Group"
              api-path="/api/converter_image_groups/"
              clearable
              :format-entry="x => Object.assign({ friendlyName: x.name || '' }, x)"
            />
          </v-col>
        </v-row>
        <v-row v-if="pictureSet">
          <v-col
            cols="12"
            sm="6"
          >
            {{ pictureSet.name }}
            <v-sheet
              max-height="300"
              max-width="300"
            >
              <converter-image-viewer
                :photo-group="pictureSet"
              />
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              :items="$store.getters['converterStore/converterGroups']"
              label="Converter Group"
              v-model="converterGroup"
              item-value="url"
              item-text="name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-list>
              <v-list-item-title>
                Make Models
              </v-list-item-title>
              <v-list-item
                v-for="(mm, index) in makeModelsSet"
                :key="index"
              >
                <v-btn
                  small
                  :color="makeModelsToRemove.includes(mm.id) ? 'accent' : 'primary'"
                  @click="toggleRemoval(mm.id)"
                >
                  {{ mm.make }} {{ mm.model }}
                  <v-icon
                    class="ml-2"
                    small
                  >
                    mdi-close-circle-outline
                  </v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <search-autocomplete
              v-model="makeModelToAdd"
              label="Add Make Models"
              api-path="/api/make_models/"
              :params="{ page_size: 0 }"
              :format-entry="x => Object.assign(x, { friendlyName: x.friendly_name })"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-list>
              <v-list-item-title>
                Vehicles
              </v-list-item-title>
              <v-list-item
                v-for="(mm, index) in vehiclesSet"
                :key="index"
              >
                <v-btn
                  small
                  :color="vehiclesToRemove.includes(mm.id) ? 'accent' : 'primary'"
                  @click="toggleVehicleRemoval(mm.id)"
                >
                  {{ mm.friendly_name }}
                  <v-icon
                    class="ml-2"
                    small
                  >
                    mdi-close-circle-outline
                  </v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <search-autocomplete
              v-model="vehicleToAdd"
              label="Add Vehicle"
              api-path="/api/vehicles/"
              :format-entry="x => Object.assign(x, { friendlyName: x.friendly_name })"
            />
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-form>
  </edit-dialog>
</template>

<script>
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import ConverterImageViewer from '@/base/components/ConverterImageViewer'

export default {
  name: 'ConverterEdit',
  components: { ConverterImageViewer, SearchAutocomplete },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    converter: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    fields: Object(),
    valid: true,
    pictureSet: null,
    converterGroup: null,
    makeModelsSet: [],
    makeModelsToRemove: [],
    makeModelToAdd: null,
    vehiclesSet: [],
    vehiclesToRemove: [],
    vehicleToAdd: null
  }),
  watch: {
    value (opened) {
      if (opened) {
        this.vehiclesSet = this.$store.getters['catalogStore/vehicles']
      }
    },
    makeModelToAdd (value) {
      if (!this.makeModelsSet.find(mm => mm.id === value.id)) this.makeModelsSet.push(value)
    },
    vehicleToAdd (value) {
      if (!this.vehiclesSet.find(mm => mm.id === value.id)) this.vehiclesSet.push(value)
    },
    converter: {
      immediate: true,
      handler (converter) {
        if (converter) {
          this.code = converter.code
          this.notes = converter.notes
          this.pictureSet = converter.picture_set
          this.converterGroup = converter.converter_group
          this.makeModelsSet = [...converter.make_models]
        }
      }
    }
  },
  methods: {
    toggleRemoval (id) {
      if (this.makeModelsToRemove.includes(id)) {
        this.makeModelsToRemove = this.makeModelsToRemove.filter(mm => mm !== id)
      } else {
        this.makeModelsToRemove.push(id)
      }
    },
    toggleVehicleRemoval (id) {
      if (this.vehiclesToRemove.includes(id)) {
        this.vehiclesToRemove = this.vehiclesToRemove.filter(mm => mm !== id)
      } else {
        this.vehiclesToRemove.push(id)
      }
    },
    getMakeModelIds () {
      return this.makeModelsSet.map(mm => mm.id).filter(id => !this.makeModelsToRemove.includes(id))
    },
    getVehicleIds () {
      return this.vehiclesSet.map(mm => mm.id).filter(id => !this.vehiclesToRemove.includes(id))
    },
    async updateConverter () {
      const id = this.converter.id
      let payload = this.fields
      payload['converter_group'] = this.converterGroup
      payload['make_models'] = this.getMakeModelIds()
      payload['vehicles'] = this.getVehicleIds()
      if (this.pictureSet && this.pictureSet.id) {
        payload['picture_set_id'] = this.pictureSet.id
      }
      // must before dispatch because it updates the object
      await this.$store.dispatch('catalogStore/updateConverter', { id, payload })
      // re-fetches vehicles after update.
      // this code is spaghetti
      this.$store.dispatch('catalogStore/fetchVehicles')
      this.$emit('input', false)
    }
  },
  mounted () {
    this.$store.dispatch('converterStore/fetchConverterGroups')
  }
}
</script>
