<template>
  <ValidationObserver
    v-slot="{ invalid }"
    ref="validationObserver"
  >
    <v-form @submit.prevent="createConverter">
      <ValidationProvider
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          v-model="code"
          label="Code"
          :error-messages="errors"
        />
      </ValidationProvider>
      <ValidationProvider
        rules="required"
        v-slot="{ errors }"
      >
        <v-select
          label="Converter Group"
          v-model="converterGroup"
          :items="converterGroups"
          :error-messages="errors"
          item-text="name"
          item-value="url"
        />
      </ValidationProvider>
      <ValidationProvider
        rules="required"
        v-slot="{ errors }"
      >
        <paginated-autocomplete
          label="Make/Models"
          v-model="makeModels"
          search-field="make"
          item-text="friendly_name"
          item-value="id"
          multiple
          base-url="/api/make_models/"
          :error-messages="errors"
        />
      </ValidationProvider>
      <v-textarea
        v-model="notes"
        label="Notes (Optional)"
      />
      <base-button
        auto-center
        :disabled="invalid || loading"
        :loading="loading"
        type="submit"
        label="Add"
      />
    </v-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AddConverter',
  props: {
    converterTypes: { type: Array, required: true }
  },
  data: () => ({
    code: '',
    notes: '',
    makeModels: [],
    loading: false,
    converterGroup: ''
  }),
  computed: {
    ...mapGetters({
      converterGroups: 'converterStore/converterGroups'
    })
  },
  methods: {
    /**
     * Tell the parent to create a new converter
     */
    createConverter () {
      this.loading = true
      const body = {
        code: this.code,
        notes: this.notes,
        converterGroup: this.converterGroup,
        makeModels: this.makeModels.map(mm => mm.id)
      }
      this.$emit('createConverter', body)
    }
  }
}
</script>
