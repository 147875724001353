<!--The responsibility of this component is to display and emit user entered search params-->
<template>
  <div>
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="fetchConverterGroupNames"
    >
      <date-range-input
        :start-date="startDate"
        :end-date="endDate"
        @alterEndDate="endDate = $event"
        @alterStartDate="startDate = $event"
      />
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
            clearable
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Converter ID"
            v-model="converterId"
            type="number"
            clearable
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Converter Groups"
            v-model="converterGroups"
            :items="converterGroupOptions"
            clearable
            multiple
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Material Types"
            v-model="materialTypesProp"
            :items="getMaterials"
            clearable
            multiple
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Unit of Measure"
            v-model="unitsOfMeasureProp"
            :items="unitsOfMeasure"
            clearable
            multiple
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Cat Name"
            v-model="catName"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Category"
            v-model="categoryProp"
            :items="getConverterTypes"
            clearable
          />
        </v-col>
        <v-col
          cols="12"
          offset-sm="6"
          sm="6"
        >
          <v-checkbox
            label="No Converter Category"
            v-model="noCategoryProp"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            label="Name"
            v-model="name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            label="Notes (Classic Converter Search)"
            v-model="notes"
          />
        </v-col>
      </v-row>
      <template v-slot:extras>
        <v-row>
          <v-text-field
            v-model="catNameStrict"
            label="Strict Cat Name"
            hint="Search will only match exact characters"
            append-outer-icon="mdi-magnify"
            @click:append-outer="$emit('submitSearch')"
            @keydown.enter="routePage"
          />
        </v-row>
      </template>
    </search-filters>
    <div
      class="d-flex justify-center mb-8"
    >
      <div>
        <small>
          <em>
            For better code results when searching for codes exclude spaces and special characters (use alpha or numeric
            only).
          </em>
        </small>
      </div>
    </div>
    <!--don't load the v-data-table until the pagination is synced-->
    <CatalogAdvancedSearchTable
      v-if="paginationReady"
      :init-options="pagination"
      :total-count="totalCount"
      :items="resultList"
      @updated="routePage"
    >
      <v-checkbox
        label="Show Test Units"
      />
      <v-checkbox
        label="Show Catalog"
      />
    </CatalogAdvancedSearchTable>
    <v-card>
      <v-card-text class="font-weight-black">
        <v-skeleton-loader
          v-if="this.averages === null"
          type="list-item-three-line"
        />
        <div v-else>
          <v-row>
            <v-col>
              Counted {{ averages.count }}
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span
                    v-on="on"
                  >
                    Price ${{ averages.price | toFixed(2) }}
                  </span>
                </template>
                <span>{{ averages.price_msg }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="$store.getters.can('view-ppm')"
              class="pt-color"
            >
              {{ averages.pt_ppm }} ppm
            </v-col>
            <v-col
              v-if="$store.getters.can('view-ppm')"
              class="pd-color"
            >
              {{ averages.pd_ppm }} ppm
            </v-col>
            <v-col
              v-if="$store.getters.can('view-ppm')"
              class="rh-color"
            >
              {{ averages.rh_ppm }} ppm
            </v-col>
            <v-col v-if="$store.getters.can('view-ppm')">
              {{ averages.yield_lbs }} lbs
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <bulk-update-catalog
      v-if="$store.getters.can('bulk-update-catalog')"
      @confirm="bulkUpdateConverters"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CatalogAdvancedSearchTable from './CatalogAdvancedSearchTable'
import axios from 'axios'
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import BulkUpdateCatalog from '@/views/Catalog/Components/BulkUpdateCatalog.vue'

export default {
  mixins: [chipsMixin, paginationMixin],
  name: 'CatalogAdvancedSearch',
  components: { BulkUpdateCatalog, SearchFilters, CatalogAdvancedSearchTable },
  data: function () {
    return {
      pageName: 'catalog-list',

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' },
        { name: 'Notes', prop: 'notes', type: String, query: 'converter__notes__icontains' },
        { name: 'Materials', prop: 'materialTypesProp', type: Array, query: 'material_type__in' },
        { name: 'Units', prop: 'unitsOfMeasureProp', type: Array, query: 'unit_of_measure__in' },
        {
          name: 'Converter Group',
          prop: 'converterGroups',
          type: Array,
          query: 'converter__converter_group__name__in'
        },
        {
          name: 'No Converter Category',
          prop: 'noCategoryProp',
          type: Boolean,
          query: 'converter__converter_group__isnull'
        },
        { name: 'End Date', prop: 'endDate', type: String, query: 'end_date' },
        { name: 'Start Date', prop: 'startDate', type: String, query: 'start_date' },
        { name: 'Cat Name', prop: 'catName', type: String, query: 'converter__cat_name__icontains' },
        { name: 'Strict Cat Name', prop: 'catNameStrict', type: String, query: 'converter__cat_name__iexact' },
        { name: 'Category', prop: 'categoryProp', type: String, query: 'category__icontains' },
        { name: 'Converter', prop: 'converterId', type: String, query: 'converter__id' }
      ]),

      // unique
      averages: null,
      converterGroupOptions: []
    }
  },
  computed: {
    ...mapGetters([
      'getMaterials',
      'unitsOfMeasure',
      'getConverterTypes'
    ])
  },
  methods: {
    fetchConverterGroupNames () {
      // Lazy load only once
      if (this.converterGroupOptions.length) return
      // Specialized for Converter Groups
      axios.get('/api/converter_groups/', {
        params: {
          page_size: 0,
          fields: 'name'
        }
      }).then(res => {
        if (res.status === 200) {
          this.converterGroupOptions = res.data.map(g => g.name)
        }
      })
    },
    async bulkUpdateConverters (changes) {
      let params = this.getQueryParams()
      if (params.hasOwnProperty('page')) delete params.page
      if (params.hasOwnProperty('ordering')) delete params.ordering

      if (Object.keys(params).length === 0) {
        this.$store.commit('setSnackbarError', 'Unable to preform updates without at least one filter')
        return
      }

      if (Object.keys(changes).length === 0) {
        this.$store.commit('setSnackbarError', 'No changes were provided')
        return
      }

      if (params.hasOwnProperty('search')) {
        this.$store.commit('setSnackbarWarning', 'Unable to do bulk updates when using the general `search` field')
        return
      }

      try {
        const res = await axios.post('/api/catalogs/bulk_update/', changes, { params })
        this.executeSearch()
        this.$store.commit('setSnackbarSuccess', res.data?.message)
      } catch {
        this.$store.commit('setSnackbarError', 'Failed to update catalog')
      }
    },
    // Creates and submits query parameters
    async executeSearch () {
      // eslint-disable-next-line no-undef
      const params = this.getQueryParams()
      axios.get('/api/catalogs/', { params }).then(this.setResponseResults)
      // specialized for the Catalog Advanced Search
      axios.get('/api/catalogs/averages/', { params }).then(response => {
        this.averages = response.data
      })
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
