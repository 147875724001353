<template>
  <unauthorized
    :authorized="$store.getters.can('create-ppm')"
    message="Not Authorized to view this page"
  >
    <v-container>
      <base-title title="Create PPM Calculation" />
      <v-sheet class="pa-4">
        <div class="caption">
          Please enter a name to create a PPM Calculator. You'll be redirected to the page to enter any necessary filters.
        </div>
        <v-text-field
          v-model="name"
          label="Name"
        />
        <v-text-field
          v-model="memo"
          label="Notes (Optional)"
        />
        <base-button
          auto-center
          label="Create"
          @clicked="createPpmCalculation"
          :disabled="!name || creatingPpmCalc"
        />
      </v-sheet>
    </v-container>
  </unauthorized>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CreatePpmCalculation',
  data: () => ({
    name: null,
    creatingPpmCalc: false,
    memo: ''
  }),
  methods: {
    ...mapActions({
      createPpmCalculator: 'ppmCalculationStore/createPpmCalculation'
    }),
    /**
     * Create a new ppm calculator
     * @param {Object} body
     */
    createPpmCalculation () {
      this.creatingPpmCalc = true
      this.$store.dispatch('ppmCalculationStore/createPpmCalculation', { name: this.name, memo: this.memo }).then(id => {
        this.creatingPpmCalc = false
        if (id) this.$router.push({ name: 'ppm-calculation', params: { id } })
      })
    }
  }
}
</script>
