var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('result-pagination',{attrs:{"pages":Math.ceil(_vm.totalCount / 20)},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}}),_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{ 'items-per-page-options': [20] },"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link-id',{attrs:{"id":item.id,"name":"catalog"}})]}},{key:"item.converter",fn:function(ref){
var item = ref.item;
return [(item.converter && (item.converter.static_picture_set || item.converter.picture_set))?_c('div',[_c('v-sheet',{attrs:{"max-width":"150","max-height":"150"}},[_c('converter-image-viewer',{attrs:{"photo-group":item.converter.static_picture_set || item.converter.picture_set}})],1)],1):_c('p',[_vm._v(" No Image ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filterNA")(item.name))+" ")]}},{key:"item.converter__cat_name",fn:function(ref){
var item = ref.item;
return [(item.converter)?_c('span',[_vm._v(" "+_vm._s(_vm._f("filterNA")(item.converter.cat_name))+" ")]):_vm._e()]}},{key:"item.converter__converter_group__name",fn:function(ref){
var item = ref.item;
return [(item.converter)?_c('span',[_vm._v(" "+_vm._s(item.converter.converter_group_name)+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filterNA")(_vm._f("filterObject")(item.converter,'notes')))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filterNA")(item.description))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(item.converter)?_c('span',[_c('fx-tag',{attrs:{"fx":item.converter.base_price}})],1):(item.price)?_c('span',[_c('fx-tag',{attrs:{"fx":item.price_fx,"value":item.price,"cur":item.fx_currency}})],1):_c('span',[_vm._v(" -- ")])]}}])}),_c('result-pagination',{attrs:{"pages":Math.ceil(_vm.totalCount / 20)},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }