<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
    />
    <edit-dialog
      v-model="dialog"
      headline-text="Select Inbound Catalog to Merge"
      accept-text="merge"
      :inputs-valid="valid"
      @accept="mergeCatalog"
    >
      <ul>
        <li class="red--text">
          The <strong>Inbound Catalog</strong> selected will be completely removed.
        </li>
        <li>
          Make/Models and Assays will be moved to the <strong>Active Catalog</strong>.
        </li>
        <li>
          All <strong>Incoming Catalog</strong> references will be pointed to the <strong>Active Catalog</strong>.
        </li>
        <li>
          Before merging it's recommended that all values are updated on the <strong>Active Catalog</strong>.
        </li>
        <li>
          This is a non-reversible action.
        </li>
      </ul>
      <search-autocomplete
        v-model="inboundCatalog"
        label="Lookup an Inbound Catalog"
        api-path="/api/catalogs/"
        :format-entry="x => (Object.assign({}, x, { friendlyName: `${x.id} ${x.name}` }))"
      />
      <ul>
        <li
          class="red--text"
          v-for="(error, index) in errors"
          :key="index"
        >
          {{ error }}
        </li>
      </ul>
      <div v-if="errors.length === 0 && inboundCatalog">
        Merge
        <strong>
          Inbound Catalog
          <router-link-id
            :id="inboundCatalog.id"
            name="catalog"
          />
        </strong>
        into
        <strong>
          Active Catalog
          <router-link-id
            :id="catalog.id"
            name="catalog"
          />
        </strong>
        .
        <h3>Catalog</h3>
        <h4>{{ inboundCatalog.name }} (#{{ inboundCatalog.id }})</h4>
        <p>
          {{ inboundCatalog.description }}
        </p>
        <h3>Converter</h3>
        <p>
          {{ inboundCatalog.converter.code }}
          <br>
          {{ inboundCatalog.converter.notes }}
        </p>
        <h3>Assays</h3>
        <assay-section
          :assays="inboundCatalog.converter.assay_details"
        />
      </div>
    </edit-dialog>
  </div>
</template>

<script>
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import AssaySection from '@/views/Catalog/Components/AssaySection'
import axios from 'axios'
export default {
  name: 'MergeCatalogDialog',
  components: { AssaySection, SearchAutocomplete },
  props: {
    catalog: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    inboundCatalog: null
  }),
  computed: {
    // returns boolean to ok submission
    valid () {
      if (this.inboundCatalog === null) return false
      return !this.errors.length
    },
    errors () {
      let errors = []
      if (this.inboundCatalog) {
        if (this.inboundCatalog.id === this.catalog.id) {
          errors.push('Can not merge catalog into self.')
        }
        if (!this.inboundCatalog.converter) {
          errors.push('This catalog is not a converter.')
        }
      }
      return errors
    }
  },
  methods: {
    mergeCatalog () {
      if (confirm(`Are you sure you want to merge Catalog #${this.inboundCatalog.id} into Catalog #${this.catalog.id}`)) {
        axios.post(this.catalog.url + 'merge_catalog/', { inbound_catalog: this.inboundCatalog.id }).then(() => {
          this.$store.commit('setSnackbarInfo', 'Catalog ' + this.inboundCatalog.id + ' has been merged.')
          this.inboundCatalog = null
          this.$emit('merged')
        })
      }
    }
  }
}
</script>
