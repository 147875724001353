<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Yard
          </v-list-item-title>
          <div v-if="ppmCalculation.filter_yard_only">
            {{ yardName || 'getting yard name' }}
          </div>
          <div v-else>
            All Yards Included
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Lot Types
          </v-list-item-title>
          {{ ppmCalculation.filter_material_type_only ? ppmCalculation.filter_material_type_only : 'All Lot Types Included' }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Converter Types
          </v-list-item-title>
          {{ formattedConverterTypes }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Historical Record Limit
          </v-list-item-title>
          {{ ppmCalculation.filter_max_history }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Assigned Only
            <info-tooltip>
              Only allow summaries connected to lots that are using this calculator
            </info-tooltip>
          </v-list-item-title>
          {{ ppmCalculation.assigned_only ? 'Yes' : 'No' }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FiltersCard',
  props: {
    ppmCalculation: { type: Object, required: true }
  },
  data: () => ({
    yardName: null
  }),
  watch: {
    ppmCalculation: {
      immediate: true,
      handler (calc) {
        if (calc.filter_yard_only) {
          axios.get(calc.filter_yard_only, { params: { fields: 'name' } }).then(r => {
            if (r.data && r.data.name) this.yardName = r.data.name
          })
        }
      }
    }
  },
  computed: {
    // Used to show what converter types are included in the ppm calc
    formattedConverterTypes () {
      let cTypes = []
      if (this.ppmCalculation.filter_include_AM) cTypes.push('Aftermarket')
      if (this.ppmCalculation.filter_include_BD) cTypes.push('Bead')
      if (this.ppmCalculation.filter_include_IF) cTypes.push('Import Foil')
      if (this.ppmCalculation.filter_include_DF) cTypes.push('Domestic Foil')
      if (this.ppmCalculation.filter_include_OA) cTypes.push('OEM/AM')
      if (this.ppmCalculation.filter_include_OM) cTypes.push('OEM')
      if (this.ppmCalculation.filter_include_DS) cTypes.push('Diesel')
      if (this.ppmCalculation.filter_include_GN) cTypes.push('Generic')
      if (this.ppmCalculation.filter_include_DP) cTypes.push('DPF')
      return cTypes.length ? cTypes.join(', ') : 'All Types'
    }
  }
}
</script>
