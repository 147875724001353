<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-card
      v-if="!loading"
      min-height="90vh"
    >
      <v-toolbar
        dense
        dark
      >
        <v-toolbar-title>
          Filter Control
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            @click="$emit('input', false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!--Filtering Options-->
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              small
              color="accent"
              :disabled="!warningCount"
              @click="warningsDialog = !warningsDialog"
            >
              Show Warnings ({{ warningCount }})
            </v-btn>
            <v-switch
              color="info"
              label="Show initial"
              v-model="showInitial"
            />
            <v-checkbox
              label="Foils calculator"
              v-model="foilsCalculator"
            />
            <!--Maybe switch this with a standard autocomplete-->
            <search-autocomplete
              v-if="value && yardReady"
              v-model="selectedYard"
              :params="{ page_size: 0, fields: 'id,url,name', ordering: 'name' }"
              label="Yard Select"
              api-path="/api/yards/"
              clearable
              :format-entry="x => ({friendlyName: x.name, id: x.id, name: x.name, url: x.url})"
              @input="calcParams"
            />
            <v-select
              label="Select Lot Designation"
              v-model="selectedLotDesignation"
              :items="lotTypeList"
              @change="calcParams"
            />
            <v-select
              label="Select Converter Types"
              v-model="selectedConverterTypes"
              :items="foilsCalculator ? converterTypes.filter(t => ['IF', 'DF'].includes(t.value)) : converterTypes"
              @change="calcParams"
              return-object
              multiple
              chips
              deletable-chips
              small-chips
            />
            <v-text-field
              label="Historical Record Limit"
              type="number"
              @input="calcParams"
              v-model="historicalLimit"
            />
            <v-switch
              label="Assigned Only"
              v-model="assignedOnly"
              @change="calcParams"
            />
          </v-col>
          <!--PPM Value Tables-->
          <v-col
            cols="12"
            md="10"
          >
            <v-row>
              <v-col
                v-show="showInitial"
                cols="12"
                lg="6"
              >
                <h2>Initial</h2>
                <ppm-values
                  :foils-calc="foilsCalculator"
                  :ppm-calculation="ppmCalc"
                />
              </v-col>
              <v-col
                cols="12"
                :lg="showInitial ? 6 : 12"
              >
                <h2>Filtered</h2>
                <ppm-values
                  :foils-calc="foilsCalculator"
                  :ppm-calculation="calculatedValues.calculations"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Excluded Summaries</p>
            <excluded-summary-chips
              :excluded-summary-urls="excludedLotSummaries"
              @clear="removeExcludeSummary"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>Included Summaries</p>
            <lots-table
              :editing="true"
              :lot-summaries="calculatedValues.lot_summaries"
              @excludeSummary="excludeSummary"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="accent"
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="savePpmFilters"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <edit-dialog
      v-model="warningsDialog"
      headline-text="Shipment Summary Warnings"
      :show-decline="false"
    >
      <v-list>
        <v-list-item
          v-for="(warning, index) in calculatedValues.warnings"
          :key="index"
        >
          {{ warning }}
        </v-list-item>
      </v-list>
    </edit-dialog>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import PpmValues from './PpmValues'
import LotsTable from './LotsTable'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import ExcludedSummaryChips from './ExcludedSummaryChips'

export default {
  name: 'FiltersDialog',
  components: {
    ExcludedSummaryChips,
    SearchAutocomplete,
    LotsTable,
    PpmValues
    // LotsTable
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    ppmCalc: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    assignedOnly: false,
    yardReady: false,
    warningsDialog: false,
    showInitial: true,
    historicalLimit: 6,
    selectedLotDesignation: null,
    selectedConverterTypes: null,
    selectedYard: null,
    toggleListView: true,
    loading: true,
    fetching: false,
    excludedLotSummaries: [],
    foilsCalculator: false
  }),
  watch: {
    value (value) {
      // effectively lazy loading and reloading on open
      if (value) this.setInit()
    },
    selectedYard () {
      this.calcParams()
    }
  },
  computed: {
    ...mapGetters({
      lotTypes: 'lotStore/lotTypes',
      converterTypes: 'lotStore/lotMaterialType',
      lotSummaries: 'shipmentSummaryStore/shipmentSummaryLots',
      calculatedValues: 'ppmCalculationStore/calculatedValues'
    }),
    warningCount () {
      return this.calculatedValues.warnings.length
    },
    lotTypeList () {
      return [ { text: 'All Types', value: null }, ...this.lotTypes ]
    },
    // formats the lots returned from calculator to be viewable in lots table
    ppmLots () {
      return this.lotSummaries
    }
  },
  methods: {
    ...mapActions({
      calculate: 'ppmCalculationStore/calculatePpmValues',
      updatePpmCalculation: 'ppmCalculationStore/updatePpmCalculation'
    }),
    excludeSummary (summaryUrl) {
      this.excludedLotSummaries.push(summaryUrl)
      this.calcParams()
    },
    removeExcludeSummary (summaryUrl) {
      this.excludedLotSummaries = this.excludedLotSummaries.filter(s => s !== summaryUrl)
      this.calcParams()
    },
    // Sets initial data based on ppm calc
    async setInit () {
      if (this.ppmCalc.filter_yard_only) {
        // fetch the selected yard
        const params = { fields: 'id,url,name' }
        const response = await axios.get(this.ppmCalc.filter_yard_only, { params })
        if (response.data) {
          this.selectedYard = response.data
          this.selectedYard.friendlyName = response.data.name
        }
      }
      this.foilsCalculator = this.ppmCalc.foils_only
      this.assignedOnly = this.ppmCalc.assigned_only
      this.yardReady = true
      this.historicalLimit = this.ppmCalc.filter_max_history
      this.selectedLotDesignation = this.ppmCalc.filter_material_type_only
      this.excludedLotSummaries = this.ppmCalc.excluded_summaries

      let cTypes = []
      if (this.ppmCalc.filter_include_AM) cTypes.push({ value: 'AM', text: 'After Market' })
      if (this.ppmCalc.filter_include_BD) cTypes.push({ value: 'BD', text: 'Bead' })
      if (this.ppmCalc.filter_include_IF) cTypes.push({ value: 'IF', text: 'Import Foil' })
      if (this.ppmCalc.filter_include_DF) cTypes.push({ value: 'DF', text: 'Domestic Foil' })
      if (this.ppmCalc.filter_include_OA) cTypes.push({ value: 'OA', text: 'OEM/After Market' })
      if (this.ppmCalc.filter_include_OA) cTypes.push({ value: 'OD', text: 'OEM/After Market/DPF' })
      if (this.ppmCalc.filter_include_OM) cTypes.push({ value: 'OM', text: 'OEM' })
      if (this.ppmCalc.filter_include_DS) cTypes.push({ value: 'DS', text: 'Diesel' })
      if (this.ppmCalc.filter_include_GN) cTypes.push({ value: 'GN', text: 'Generic' })
      if (this.ppmCalc.filter_include_DP) cTypes.push({ value: 'DP', text: 'DPF' })
      this.selectedConverterTypes = cTypes.length > 0 ? cTypes : null
      this.calcParams()
    },
    // Hits endpoint /ppm_calculations/calculate/ with selected params
    calcParams () {
      this.fetching = true
      let types = []
      if (this.selectedConverterTypes) {
        for (let type of this.selectedConverterTypes) {
          if (type.value === 'AM') types.push('AM')
          if (type.value === 'BD') types.push('BD')
          if (type.value === 'DP') types.push('DP')
          if (type.value === 'DS') types.push('DS')
          if (type.value === 'IF') types.push('IF')
          if (type.value === 'DF') types.push('DF')
          if (type.value === 'GN') types.push('GN')
          if (type.value === 'OA') types.push('OA')
          if (type.value === 'OM') types.push('OM')
        }
      }
      let params = { types: types.length > 0 ? types.toString() : null }
      if (this.selectedYard) params.yard_id = this.selectedYard.id
      if (this.selectedLotDesignation) params.designation = this.selectedLotDesignation
      if (this.historicalLimit) params.limit = this.historicalLimit
      if (this.assignedOnly) params.assigned_to = this.ppmCalc.id
      if (this.foilsCalculator) params.foils_only = this.foilsCalculator
      if (this.excludedLotSummaries.length > 0) {
        params.exclude_summaries = this.excludedLotSummaries.map(url => {
          const match = url.match(/(\d*)\/?$/gm)
          return match[0].replace('/', '')
        }).join(',')
      }

      this.calculate(params).then(() => {
        this.loading = false
        this.fetching = false
      })
    },
    // updates the ppm calculator fields with filters selected
    savePpmFilters () {
      let body = {
        id: this.ppmCalc.id,
        filter_include_AM: false,
        filter_include_BD: false,
        filter_include_DP: false,
        filter_include_DS: false,
        filter_include_DF: false,
        filter_include_IF: false,
        filter_include_GN: false,
        filter_include_OA: false,
        filter_include_OM: false,
        excluded_summaries: this.excludedLotSummaries
      }
      if (this.selectedConverterTypes && this.selectedConverterTypes.length > 0) {
        this.selectedConverterTypes.forEach(type => {
          if (`filter_include_${type.value}` in body) body[`filter_include_${type.value}`] = true
        })
      }
      body.filter_yard_only = this.selectedYard ? this.selectedYard.url : null

      body.filter_material_type_only = this.selectedLotDesignation

      body.filter_max_history = this.historicalLimit

      body.assigned_only = this.assignedOnly

      body.foils_only = this.foilsCalculator

      this.updatePpmCalculation(body).then(() => {
        this.$emit('update')
        this.$emit('input', false)
      })
    }
  }
}
</script>
